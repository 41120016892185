import React from 'react';
import { groupBy, sortBy } from 'underscore';
import { TransactionRows } from './TransactionRow';

const SUGGESTED = 'Suggested';
const UNCATEGORIZED = 'Uncategorized';
const SERVICE = 'Service';
const FOOD_AND_DRINK = 'Food and Drink';

function moveToFront(array: string[], item: string) {
  if (array.includes(item)) {
    const indexOfSuggested = array.indexOf(item);
    array.splice(indexOfSuggested, 1);
    array = [item, ...array];
  }
  return array;
}

function moveToLast(array: string[], item: string) {
  if (array.includes(item)) {
    const indexOfSuggested = array.indexOf(item);
    array.splice(indexOfSuggested, 1);
    array = [...array, item];
  }
  return array;
}

export default function RecurringExpensesByCategory({ accountsById, expenses, marginBottom = 4, ...props }) {
  const expensesByCategory = groupBy(expenses, (expense) => {
    if (expense.plaidRecurringStreamId) return SUGGESTED;
    return expense.category ? expense.category[0] : UNCATEGORIZED;
  });

  let categories = sortBy(Object.keys(expensesByCategory));
  categories = moveToFront(categories, SERVICE);
  categories = moveToFront(categories, SUGGESTED);
  categories = moveToLast(categories, FOOD_AND_DRINK);

  return (
    <>
      {categories.map((category, i) => (
        <section key={category} className={i < categories.length - 1 ? `mb-${marginBottom}` : undefined}>
          <div
            style={{
              position: 'sticky',
              top: 0,
              background: 'var(--bs-body-bg)',
              padding: '0.6em 0',
              marginBottom: '0.8em',
              borderBottom: '1px solid var(--bs-border-color)',
              zIndex: 1,
            }}
          >
            <strong>{category}</strong>
          </div>

          <TransactionRows
            accountsById={accountsById}
            transactions={sortBy(expensesByCategory[category], 'amount').reverse()}
            showCategoryStartingAt={category === SUGGESTED ? 0 : 1}
            toggleRecurringOnly
            {...props}
          />
        </section>
      ))}
    </>
  );
}
