import React from 'react';
import { Timescale } from '../shared/BudgetCalendar';
import formatCurrency from '../shared/formatCurrency';
import flipSign from '../shared/flipSign';
import NoWrap from './NoWrap';

interface DollarAmountProps {
  amount: number;
  color?: boolean;
  per?: Timescale;
  keepSign?: boolean;
  showDecimals?: boolean;
  absolute?: boolean;
}

export default function DollarAmount({
  amount,
  color = false,
  per,
  keepSign = false,
  showDecimals = false,
  absolute = false,
}: DollarAmountProps) {
  if (amount === undefined) return null;

  let adjustedAmount = amount;

  if (absolute) {
    keepSign = true;
  }

  if (!keepSign) adjustedAmount = flipSign(amount);
  if (amount === -0) adjustedAmount = 0;

  const formattedAmount = formatCurrency(adjustedAmount, {
    absolute,
    decimals: showDecimals ? 'always' : 'ifNotZero',
  });

  return (
    <NoWrap>
      <span className={color && adjustedAmount < 0 ? 'text-danger' : ''} style={{ transition: 'all 300ms' }}>
        {formattedAmount}
      </span>
      {per && <small className="text-muted"> / {per === Timescale.Week ? 'wk' : 'mo'}</small>}
    </NoWrap>
  );
}
