import React, { useCallback, useEffect } from 'react';
import { Form, InputGroup, Modal, Stack } from 'react-bootstrap';
import BetterButton from './BetterButton';
import { BsFilter } from 'react-icons/bs';
import { TransactionFilters } from '../types';
import useAccounts from '../hooks/useAccounts';
import { groupBy } from 'underscore';

export default function TransactionFilter({ setFilters }: { setFilters: Function }) {
  const { accountsById } = useAccounts();

  const [showModal, setShowModal] = React.useState(false);
  const [filterName, setFilterName] = React.useState('');
  const [onlyIgnored, setOnlyIgnored] = React.useState(false);
  const [onlyRecurring, setOnlyRecurring] = React.useState(false);
  const [onlyRecovered, setOnlyRecovered] = React.useState(false);
  const [minAmount, setMinAmount] = React.useState<number>(0);
  const [maxAmount, setMaxAmount] = React.useState<number>(0);
  const [accountsSelected, setAccountsSelected] = React.useState<string[]>([]);
  const [onlyPending, setOnlyPending] = React.useState(false);
  const [onlySettled, setOnlySettled] = React.useState(false);
  const [filterCount, setFilterCount] = React.useState(0);

  useEffect(() => {
    const filters: TransactionFilters = {};
    if (filterName) filters.nameFilter = filterName.trim();
    if (onlyIgnored) filters.isIgnoredFilter = true;
    if (onlyRecurring) filters.isRecurringFilter = true;
    if (onlyRecovered) filters.isRecoveredFilter = true;
    if (onlyPending) filters.isPendingFilter = true;
    if (onlySettled) filters.isSettledFilter = true;
    if (minAmount) filters.minAmountFilter = minAmount;
    if (maxAmount) filters.maxAmountFilter = maxAmount;
    if (accountsSelected.length > 0) filters['accountsFilter'] = accountsSelected;

    setFilters(filters);
    setFilterCount(Object.keys(filters).length);
  }, [filterName, onlyIgnored, onlyRecurring, onlyRecovered, onlyPending, onlySettled, minAmount, maxAmount, accountsSelected, setFilters]);

  const removeAccount = useCallback(
    (accountId: string) => {
      setAccountsSelected(accountsSelected.filter((id) => id !== accountId));
    },
    [setAccountsSelected, accountsSelected],
  );

  const handleTypeFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setOnlyIgnored(false);
      setOnlyRecurring(false);
      setOnlyRecovered(false);

      switch (e.target.value) {
        case 'all':
          break;
        case 'recurring':
          setOnlyRecurring(true);
          break;
        case 'ignored':
          setOnlyIgnored(true);
          break;
        case 'Recovered':
          setOnlyRecovered(true);
          break;
      }
    },
    [setOnlyIgnored, setOnlyRecurring, setOnlyRecovered],
  );

  const handlePendingFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setOnlyPending(false);
      setOnlySettled(false);

      switch (e.target.value) {
        case 'all':
          break;
        case 'pending':
          setOnlyPending(true);
          break;
        case 'settled':
          setOnlySettled(true);
          break;
      }
    },
    [setOnlyPending, setOnlySettled],
  );

  const handleClearClick = useCallback(() => {
    setFilterName('');
    setOnlyIgnored(false);
    setOnlyRecurring(false);
    setOnlyRecovered(false);
    setMinAmount(0);
    setMaxAmount(0);
    setAccountsSelected([]);
    setOnlyPending(false);
    setOnlySettled(false);
  }, [
    setFilterName,
    setOnlyIgnored,
    setOnlyRecurring,
    setOnlyRecovered,
    setMinAmount,
    setMaxAmount,
    setAccountsSelected,
    setOnlyPending,
    setOnlySettled,
  ]);

  const handleFiltersClick = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilterName(e.target.value);
    },
    [setFilterName],
  );

  const handleModalClose = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const accountsByInstitution = groupBy(Object.values(accountsById), ({ institution: { name } }) => name);
  const hasFilters = filterCount > 0;

  return (
    <>
      <Stack gap={2}>
        <Stack direction="horizontal" gap={2}>
          <Form.Control type="text" placeholder="🔍 Search" onChange={handleSearchChange} value={filterName} />
          <BetterButton beforeIcon={<BsFilter />} onClick={handleFiltersClick}>
            Filters{hasFilters && <span> ({filterCount})</span>}
          </BetterButton>
          {hasFilters && <BetterButton onClick={handleClearClick}>Clear</BetterButton>}
        </Stack>
      </Stack>

      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Filter transactions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack direction={'vertical'} gap={4}>
            <fieldset>
              <legend>Transaction type</legend>
              <Form.Select
                value={onlyIgnored ? 'ignored' : onlyRecurring ? 'recurring' : onlyRecovered ? 'Recovered' : 'all'}
                onChange={handleTypeFilterChange}
              >
                <option value="all">Ignored, recurring, & recovered</option>
                <option value="recurring">Only recurring</option>
                <option value="ignored">Only ignored</option>
                <option value="Recovered">Only recovered</option>
              </Form.Select>
            </fieldset>

            <fieldset>
              <legend>Pending</legend>
              <Form.Select value={onlyPending ? 'pending' : onlySettled ? 'settled' : 'all'} onChange={handlePendingFilterChange}>
                <option value="all">Pending & settled</option>
                <option value="pending">Only pending</option>
                <option value="settled">Only settled</option>
              </Form.Select>
            </fieldset>

            <fieldset>
              <legend>Amount</legend>
              <Stack direction="horizontal" gap={3}>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    type="number"
                    placeholder="Minimum"
                    value={minAmount !== 0 ? minAmount : ''}
                    onChange={(e) => {
                      setMinAmount(e.target.value ? parseInt(e.target.value) : undefined);
                    }}
                  />
                </InputGroup>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    type="number"
                    placeholder="Maximum"
                    value={maxAmount !== 0 ? maxAmount : ''}
                    onChange={(e) => {
                      setMaxAmount(e.target.value ? parseInt(e.target.value) : undefined);
                    }}
                  />
                </InputGroup>
              </Stack>
            </fieldset>

            {accountsById && (
              <fieldset>
                <legend>Accounts</legend>
                <Stack gap={1}>
                  {Object.keys(accountsByInstitution)
                    .sort((a, b) => a.localeCompare(b))
                    .map((institutionName) => (
                      <div key={institutionName}>
                        <div className="text-muted small-font" style={{ paddingLeft: '1.5rem' }}>
                          {institutionName}
                        </div>

                        {accountsByInstitution[institutionName]
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((account) => (
                            <label className="form-check" key={account.__id}>
                              <Form.Check
                                checked={accountsSelected.includes(account.accountId)}
                                onChange={(e) => {
                                  if (e.target.checked) setAccountsSelected([...accountsSelected, account.accountId]);
                                  else removeAccount(account.accountId);
                                }}
                              ></Form.Check>
                              {account.name}
                            </label>
                          ))}
                      </div>
                    ))}
                </Stack>
              </fieldset>
            )}
          </Stack>
        </Modal.Body>
      </Modal>
    </>
  );
}
