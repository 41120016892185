import React, { ForwardedRef, MouseEventHandler } from 'react';
import { Button, ButtonProps, Spinner } from 'react-bootstrap';
import NoWrap from './NoWrap';
import { forwardRef } from 'react';

interface BetterButtonProps extends ButtonProps {
  afterIcon?: React.ReactNode;
  beforeIcon?: React.ReactNode;
  children?: React.ReactNode;
  hideLoadingIndicators?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  onClick?: MouseEventHandler;
  props?: React.ComponentProps<typeof Button>;
  size?: 'sm' | 'lg';
  style?: React.CSSProperties;
  variant?: string;
}

const BetterButton = forwardRef(
  (
    {
      isLoading,
      hideLoadingIndicators,
      loadingText,
      disabled,
      beforeIcon: BeforeIcon,
      afterIcon: AfterIcon,
      onClick,
      children,
      ...props
    }: BetterButtonProps,
    ref: ForwardedRef<any>,
  ) => {
    return (
      <Button disabled={isLoading || disabled} onClick={isLoading || disabled ? null : onClick} ref={ref} {...props}>
        <div style={{ display: 'flex', alignItems: 'center', columnGap: 6 }}>
          {isLoading && !hideLoadingIndicators ? <Spinner animation="border" size="sm" role="status" aria-hidden="true" /> : BeforeIcon}
          <NoWrap>{isLoading && loadingText && !hideLoadingIndicators ? `${loadingText}…` : children}</NoWrap>
          {AfterIcon}
        </div>
      </Button>
    );
  },
);

export default BetterButton;
export { BetterButtonProps };
