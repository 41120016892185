import { collection, query } from 'firebase/firestore';
import db from '../firebase';
import useOnSnapshot from '../hooks/useOnSnapshot';
import { AppSettingsDocData } from '../types';

export default function useAppSettings() {
  const { docs: appSettings, isLoading } = useOnSnapshot<AppSettingsDocData>(query(collection(db, 'appSettings')), {});

  const defaultAppSettings = appSettings.find((doc) => doc.__id === 'default');

  return {
    isLoading,
    appSettings: defaultAppSettings || { isMaintenanceMode: false },
  };
}
