import React from 'react';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { deleteField, doc, setDoc } from 'firebase/firestore';
import db, { recalculateBudgets } from '../firebase';
import { useAuth } from '../App';
import { Alert, Badge, Form, Stack } from 'react-bootstrap';
import { useAppLayout } from '../layouts/AppLayout';
import { MONTH_RUNWAY_PREVIOUS_PERIODS, WEEK_RUNWAY_PREVIOUS_PERIODS } from '../shared/constants';
import { HelpSectionId } from './HelpPage';
import { Link } from 'react-router-dom';
import { utcDay } from '../shared/days';
import BetterButton from '../components/BetterButton';
import { Dayjs } from 'dayjs';

/**
 * Sets the boolean setting on the User document to true when true is passed in
 * or removes the setting when false is passed in.
 * @param value
 */
async function setIsAllowanceBasedOnMovingAverage(uid: string, value: boolean) {
  const userDocRef = doc(db, 'users', uid);
  const dbValue = value || deleteField();
  await setDoc(userDocRef, { isAllowanceBasedOnMovingAverage: dbValue }, { merge: true });
}

async function changeCarryoverStartsAt(uid: string, value: Dayjs, recalculateFrom: Date) {
  const userDocRef = doc(db, 'users', uid);
  await setDoc(userDocRef, { carryoverStartsAt: value.toDate() }, { merge: true });
  recalculateBudgets(recalculateFrom);
}

function SettingsPage() {
  useDocumentTitle('Settings');
  const [newCarryoverStartsAt, setNewCarryoverStartsAt] = React.useState<string | null>(null);

  const {
    uid,
    userData: { isAllowanceBasedOnMovingAverage, carryoverStartsAt, createdAt },
  } = useAuth();

  const { setToast } = useAppLayout();

  return (
    <>
      <h3 className="mb-4">Settings</h3>

      <Stack gap={2}>
        <Alert variant="warning">Be advised: changing these settings may dramatically change how you track your spending.</Alert>

        <section>
          <h4>Safe to Spend</h4>

          <p>Configure how your monthly and weekly allowance Safe to Spend is determined for your Budget.</p>

          <Form.Check
            checked={!isAllowanceBasedOnMovingAverage}
            id="allowance-mode-discretionary-income"
            label={
              <>
                <div>
                  <strong>Net income-based (default)</strong>
                </div>
                <div className="small-font">Your recurring income minus recurring expenses, plans, and carryover</div>
              </>
            }
            name="allowance-mode"
            onChange={() => {
              setIsAllowanceBasedOnMovingAverage(uid, false);
              setToast('Setting saved!');
            }}
            type="radio"
          />
          <Form.Check
            checked={isAllowanceBasedOnMovingAverage}
            id="allowance-mode-moving-average"
            label={
              <>
                <Stack gap={2} direction="horizontal">
                  <strong>Average spending-based</strong>
                  <Badge>Beta</Badge>
                </Stack>

                <div className="small-font">
                  Based on a {WEEK_RUNWAY_PREVIOUS_PERIODS}-week or {MONTH_RUNWAY_PREVIOUS_PERIODS}-month moving average of your spending
                  (note this calculation does not include recurring income, recurring expenses, plans, or carryover)
                </div>
              </>
            }
            name="allowance-mode"
            onChange={() => {
              setIsAllowanceBasedOnMovingAverage(uid, true);
              setToast('Setting saved!');
            }}
            type="radio"
          />
        </section>

        <hr />

        <section>
          <h4>Carryover</h4>

          <p>
            Any over/under-spending from the previous week or month gets applied to this week’s or month’s Safe to Spend (
            <Link to={`/help#${HelpSectionId.RecurringExpenses}`}>learn more</Link>). Carryover first went into affect when you signed up on{' '}
            {utcDay(createdAt).format('MMM D, YYYY')}.
          </p>

          <Stack gap={2} direction="horizontal">
            <Form.Control
              type="date"
              value={newCarryoverStartsAt || (carryoverStartsAt && utcDay(carryoverStartsAt.toDate()).format('YYYY-MM-DD'))}
              onChange={(event) => {
                setNewCarryoverStartsAt(event.target.value);
              }}
            />
            <BetterButton
              variant="outline-primary"
              disabled={!newCarryoverStartsAt}
              onClick={() => {
                const utcDayNewCarryoverStartsAt = utcDay(newCarryoverStartsAt);
                let recalculateFrom = utcDayNewCarryoverStartsAt.toDate();

                if (carryoverStartsAt) {
                  const carryoverStartsAtDate = carryoverStartsAt.toDate();
                  if (utcDayNewCarryoverStartsAt.isAfter(carryoverStartsAtDate)) recalculateFrom = carryoverStartsAtDate;
                }

                changeCarryoverStartsAt(uid, utcDayNewCarryoverStartsAt, recalculateFrom);
                setToast(`Carryover set to take effect on ${utcDayNewCarryoverStartsAt.format('MMM D, YYYY')}.`);
              }}
            >
              Set start date
            </BetterButton>
          </Stack>
        </section>
      </Stack>
    </>
  );
}

export default SettingsPage;
