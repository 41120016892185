import React from 'react';
import { FadeLoader } from 'react-spinners';

interface BigSpinnerProps {
  label?: string;
  className?: string;
}

export default function BigSpinner({ label, className }: BigSpinnerProps) {
  return (
    <div className={`big-spinner ${className ? className : ''}`.trim()}>
      {label && <div className="text-muted">{label}…</div>}
      <FadeLoader height={12} color="var(--bs-secondary)" />
    </div>
  );
}
