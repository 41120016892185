import React, { useContext } from 'react';
import { WelcomeContext } from './Welcome';
import { Alert, Stack } from 'react-bootstrap';
import LineItem from './LineItem';
import SumLine from './SumLine';
import { RECURRING_EXPENSES_EXAMPLES } from '../../shared/constants';
import NavButtons from './NavButtons';
import Tip from '../Tip';
import { Metric, trackMetric } from '../../utils/metrics';

export default function RecurringExpensesStep() {
  const { recurringExpenses, setRecurringExpenses, recurringExpensesSum, monthlyIncome, allowanceMonthly } = useContext(WelcomeContext);

  function handleRecurringExpenseClicked(expenseName: string) {
    const index = recurringExpenses.findIndex((name) => name === expenseName);
    const newRecurringExpenses = index === -1 ? [...recurringExpenses, expenseName] : recurringExpenses.filter((_, i) => i !== index);

    setRecurringExpenses(newRecurringExpenses);
    trackMetric(Metric.WELCOME_RECURRING_EXPENSE_SELECTED, { expenseName });
  }

  return (
    <>
      <h1 className="mb-3">Every month, I pay for&hellip;</h1>

      <Stack gap={2} className="my-3">
        <div>
          {RECURRING_EXPENSES_EXAMPLES.map(({ name, percentOfIncome, emoji }) => (
            <LineItem
              key={name}
              transaction={{ name, amount: Math.floor(monthlyIncome * percentOfIncome * -1), icon: emoji }}
              onSelect={() => handleRecurringExpenseClicked(name)}
              selected={recurringExpenses.findIndex((expenseName) => expenseName === name) >= 0}
            />
          ))}
        </div>

        {recurringExpenses.length > 0 && (
          <>
            <SumLine />

            <LineItem transaction={{ icon: '🗓️', name: 'Estimated monthly expenses', amount: recurringExpensesSum }} />
          </>
        )}
      </Stack>

      {recurringExpenses.length > 0 && (
        <>
          <hr />

          <p>
            What’s left of your income after your recurring monthly expenses is the most you can spend on anything else (without accruing
            debt). We call this your <dfn>“allowance.”</dfn>
          </p>

          <Stack gap={2} className="my-3">
            <LineItem transaction={{ icon: '💵', name: 'Income', amount: monthlyIncome }} />
            <LineItem transaction={{ icon: '🗓️', name: 'Monthly expenses', amount: recurringExpensesSum }} />
            <SumLine />

            <LineItem transaction={{ icon: '🗓️', name: 'Allowance', amount: allowanceMonthly }} />
          </Stack>

          <Tip>Don’t stress! When you securely connect your accounts to Lopery, your allowance will be based on real data.</Tip>
        </>
      )}

      {allowanceMonthly >= 0 && (
        <Alert variant="danger" className="mt-3">
          To continue, please adjust the numbers so that your allowance is positive.
        </Alert>
      )}

      <NavButtons isNextDisabled={recurringExpenses.length === 0 || allowanceMonthly >= 0} />
    </>
  );
}
