import React, { ReactNode } from 'react';
import { Accordion, Spinner, Stack } from 'react-bootstrap';
import { BsExclamationTriangleFill } from 'react-icons/bs';

interface BetterAccordionItemProps {
  children: ReactNode;
  error?: Error;
  eventKey: string;
  header: ReactNode | string;
  icon?: ReactNode;
  isLoading?: boolean;
  key?: string;
  secondColumn?: ReactNode;
  secondLine?: ReactNode | string;
}

export default function BetterAccordionItem({
  children,
  error,
  eventKey,
  header,
  icon,
  isLoading,
  key,
  secondColumn,
  secondLine,
  ...rest
}: BetterAccordionItemProps) {
  return (
    <Accordion.Item key={key} eventKey={eventKey} {...rest}>
      <Accordion.Header>
        <div
          style={{
            display: 'flex',
            marginRight: 8,
            gap: 16,
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div style={{ flex: 1 }}>
            <Stack gap={1}>
              <Stack direction="horizontal" gap={2}>
                {isLoading ? (
                  <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
                ) : error ? (
                  <BsExclamationTriangleFill color="var(--bs-warning)" />
                ) : (
                  icon
                )}
                <strong>{header}</strong>
              </Stack>
              {error && <div className="small-font text-danger">{error.message}</div>}
              {secondLine && !error && <div className="small-font text-muted">{secondLine}</div>}
            </Stack>
          </div>
          <div>{secondColumn}</div>
        </div>
      </Accordion.Header>
      <Accordion.Body>{children}</Accordion.Body>
    </Accordion.Item>
  );
}
