/**
 * An Transaction-like interface to comform to front-end and back-end specs.
 */
interface Interogable {
  isRecurring?: boolean;
  isIgnored?: boolean;
  planId?: string;
  transactionId?: string;
}

/**
 * Return true if the Transaction is recurring. If the transaction is also ignored or amortized, return false
 * but warn about the inconsistency.
 * @param interogable
 * @returns
 */
export default function isTransactionRecurring<T extends Interogable>(interogable: T) {
  const { isRecurring, isIgnored, planId, transactionId } = interogable;

  if (isRecurring) {
    if (isIgnored) console.warn(`Recurring transaction is also ignored (${transactionId})`);
    if (planId) console.warn(`Recurring transaction is also associated with a plan (${transactionId})`);
  }

  return isRecurring && isIgnored === false && planId === undefined;
}
