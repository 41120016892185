import React from 'react';
import { BsLightbulb } from 'react-icons/bs';

interface TipProps {
  icon?: React.ReactNode;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export default function Tip({ icon = <BsLightbulb />, children, style = {} }: TipProps) {
  return (
    <div
      style={{
        display: 'flex',
        padding: '0.8em',
        gap: '0.8em',
        background: 'var(--bs-tertiary-bg)',
        borderRadius: 'var(--bs-border-radius)',
        ...style,
      }}
      className="small-font text-muted"
    >
      {icon && <div>{icon}</div>}
      <div style={{ flex: 1 }}>{children}</div>
    </div>
  );
}
