import db from '../firebase';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { STEPS as ONBOARDING_STEPS } from '../components/Onboarding';

export default async function upsertUser(authUser) {
  const userDocRef = doc(db, 'users', authUser.uid);
  const snap = await getDoc(userDocRef);

  // Data we want to update every time
  const data: any = {
    displayName: authUser.displayName,
    photoURL: authUser.photoURL,
    email: authUser.email,
    lastSeenAt: serverTimestamp(),
    timezoneOffset: new Date().getTimezoneOffset(),
  };

  if (!snap.exists()) {
    // Data we only want to set when the user is first created
    data.onboardingStep = ONBOARDING_STEPS[0];
    data.createdAt = serverTimestamp();
    data.carryoverStartsAt = serverTimestamp();
  }

  await setDoc(userDocRef, data, { merge: true });

  return data;
}
