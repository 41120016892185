import React from 'react';
import List from '../List';
import AllowanceEquationWithIcons from './AllowanceEquationWithIcons';
import { Stack } from 'react-bootstrap';

export default function PreambleStep() {
  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <strong>How we build your budget:</strong>
      </div>

      <AllowanceEquationWithIcons />

      <Stack gap={3} className="text-center">
        <div>We’ll do this in three easy steps:</div>

        <List style={{ gap: 16, marginLeft: 8, alignItems: 'center' }}>
          <List.Item>
            <strong>1.</strong> Connect your banks
          </List.Item>
          <List.Item>
            <strong>2.</strong> Review your income
          </List.Item>
          <List.Item>
            <strong>3.</strong> Identify recurring expenses
          </List.Item>
        </List>

        <div className="small-font text-muted">The whole process should take about 5 minutes.</div>
      </Stack>
    </>
  );
}
