import React from 'react';
import Accounts from '../components/Accounts';
import useDocumentTitle from '../hooks/useDocumentTitle';

function AccountsPage() {
  useDocumentTitle('Accounts');

  return <Accounts />;
}

export default AccountsPage;
