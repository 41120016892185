import React, { createContext, useContext, useEffect, useState } from 'react';
import { Modal, Stack, Toast, ToastContainer } from 'react-bootstrap';
import { BsArrowRepeat, BsEyeSlash } from 'react-icons/bs';
import { collection, FirestoreError, query } from 'firebase/firestore';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import { Metric, MetricByPagePath, trackMetric } from '../utils/metrics';
import { Route } from '../Routes';
import { useAuth } from '../App';
import useScopeStyles from '../hooks/useScopeStyles';
import {
  IgnoredTransactionsExplanation,
  RecoveryExplanation,
  RecurringExpensesExplanation,
  RecurringIncomesExplanation,
  SafeToSpendExplanation,
} from '../pages/HelpPage';
import useOnSnapshot from '../hooks/useOnSnapshot';
import useAppSettings from '../hooks/useAppSettings';
import db from '../firebase';
import Maintenance from '../components/Maintenance';

const AppLayoutContext = createContext(null);

const ExplainerModalId = Object.freeze({
  RecurringIncomes: 'recurring-incomes',
  RecurringExpenses: 'recurring-expenses',
  IgnoredTransactions: 'ignored-transactions',
  SafeToSpend: 'Safe to Spend',
  Recover: 'recover',
});

const mediaQuerylist = window.matchMedia
  ? window.matchMedia('(prefers-color-scheme: dark)')
  : { matches: false, addEventListener: () => {} };

function AppLayout() {
  useScopeStyles('app');

  const { uid, user } = useAuth();
  const { pathname } = useLocation();
  const [toast, setToast] = useState();
  const [explainerModalId, setExplainerModalId] = useState<string>();
  const [isDarkMode, setIsDarkMode] = useState(mediaQuerylist.matches);
  const [plaidItemsForUpdateOnSnapshotError, setPlaidItemsForUpdateOnSnapshotError] = useState<undefined | FirestoreError>();

  const {
    docs: plaidItemsForUpdate,
    subscribe: plaidItemsForUpdateSnapshotSubscribe,
    unsubscribe: plaidItemsForUpdateSnapshotUnsubscribe,
    isLoading: isLoadingPlaidItemsForUpdate,
  } = useOnSnapshot(query(collection(db, 'users', uid, 'plaidItemsForUpdate')), {
    onError: setPlaidItemsForUpdateOnSnapshotError,
  });

  const { appSettings } = useAppSettings();

  const openExplainerModal = (id) => {
    setExplainerModalId(id);
  };

  useEffect(() => {
    const firstPathSegment = pathname.split('/').slice(0, 2).join('/');

    switch (firstPathSegment) {
      case Route.Root:
        if (user) trackMetric(Metric.DASHBOARD_PAGE_VISITED);
        else trackMetric(Metric.HOME_PAGE_VISITED);
        break;
      default:
        const metric = MetricByPagePath[firstPathSegment];
        if (metric) trackMetric(metric);
    }
  }, [user, pathname]);

  useEffect(() => {
    const html = document.querySelector('html');
    html.setAttribute('data-bs-theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  useEffect(() => {
    mediaQuerylist.addEventListener('change', ({ matches }: MediaQueryListEvent) => setIsDarkMode(matches));
  }, []);

  function hideExplainerModal() {
    setExplainerModalId(undefined);
  }

  if (appSettings.isMaintenanceMode) return <Maintenance />;

  return (
    <AppLayoutContext.Provider
      value={{
        toast,
        setToast,
        isDarkMode,
        setIsDarkMode,
        openExplainerModal,
        plaidItemsForUpdate,
        plaidItemsForUpdateSnapshotSubscribe,
        plaidItemsForUpdateSnapshotUnsubscribe,
        isLoadingPlaidItemsForUpdate,
        plaidItemsForUpdateOnSnapshotError,
      }}
    >
      <Header />

      <main>
        <Outlet />
      </main>

      <Modal show={explainerModalId === ExplainerModalId.RecurringIncomes} onHide={hideExplainerModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <BsArrowRepeat /> Recurring income
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack gap={3}>
            <RecurringIncomesExplanation onLinkClick={hideExplainerModal} />
          </Stack>
        </Modal.Body>
      </Modal>

      <Modal show={explainerModalId === ExplainerModalId.RecurringExpenses} onHide={hideExplainerModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <BsArrowRepeat /> Recurring expenses
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack gap={3}>
            <RecurringExpensesExplanation onLinkClick={hideExplainerModal} />
          </Stack>
        </Modal.Body>
      </Modal>

      <Modal show={explainerModalId === ExplainerModalId.IgnoredTransactions} onHide={hideExplainerModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <BsEyeSlash /> Ignored transactions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack gap={3}>
            <IgnoredTransactionsExplanation onLinkClick={hideExplainerModal} />
          </Stack>
        </Modal.Body>
      </Modal>

      <Modal show={explainerModalId === ExplainerModalId.SafeToSpend} onHide={hideExplainerModal}>
        <Modal.Header closeButton>
          <Modal.Title>Safe to Spend</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack gap={3}>
            <SafeToSpendExplanation />
          </Stack>
        </Modal.Body>
      </Modal>

      <Modal show={explainerModalId === ExplainerModalId.Recover} onHide={() => setExplainerModalId(undefined)}>
        <Modal.Header closeButton>
          <Modal.Title>Recover</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack gap={3}>
            <RecoveryExplanation onLinkClick={hideExplainerModal} />
          </Stack>
        </Modal.Body>
      </Modal>

      <ToastContainer containerPosition="fixed" style={{ marginBottom: 24 }} position="bottom-center">
        <Toast show={!!toast} onClose={() => setToast(null)} delay={7000} autohide bg={isDarkMode ? 'light' : 'dark'}>
          <Toast.Body className={isDarkMode ? 'text-dark' : 'text-light'}>{toast}</Toast.Body>
        </Toast>
      </ToastContainer>
    </AppLayoutContext.Provider>
  );
}

function useAppLayout() {
  return useContext(AppLayoutContext);
}

export default AppLayout;
export { useAppLayout, ExplainerModalId };
