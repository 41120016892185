import React from 'react';
import DollarAmount from '../DollarAmount';
import { useDashboard } from '../../contexts/DashboardContext';

export default function SafeToSpendTotal() {
  const { timescale, budgetView } = useDashboard();

  return (
    <div style={{ padding: '0 calc(1.25rem + 20px + 8px) 1rem 1.25rem' }}>
      {/* 👆 Margin matches the definitions of var(--bs-accordion-btn-padding-x/y) but sadly can't be reused because this is outside of the .accordion container */}
      <div
        style={{
          margin: '0 0 8px 70%',
          justifyContent: 'space-between',
          borderBottom: '1px solid var(--bs-border-color)',
        }}
      >
        +
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <strong>Safe to Spend for the {timescale}</strong>
        </div>
        <div>
          <DollarAmount amount={budgetView.remainder} color />
        </div>
      </div>
    </div>
  );
}
