import React, { useState } from 'react';
import { Stack } from 'react-bootstrap';
import HelpfulLabel from '../HelpfulLabel';
import pluralize from '../../shared/pluralize';
import { useDashboard } from '../../contexts/DashboardContext';
import RunwayModal from './RunwayModal';
import { BsInfoCircle } from 'react-icons/bs';
import BetterButton from '../BetterButton';

function DashboardGauge() {
  const [showRunwayModal, setShowRunwayModal] = useState(false);
  const { runway, budgetView, setShowBudgetModal } = useDashboard();

  if (!budgetView) return null;

  return (
    <Stack gap={2}>
      <Stack className="text-center">
        <div
          style={{
            transition: 'color 400ms',
            fontSize: '2.2em',
            fontWeight: 'bold',
          }}
          className={`text-${budgetView.safeToSpend.color}`}
        >
          {budgetView.safeToSpend.amountFormatted}
        </div>
        <div>
          <BetterButton
            afterIcon={<BsInfoCircle />}
            variant="link"
            onClick={() => setShowBudgetModal(true)}
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            {budgetView.safeToSpend.label}
          </BetterButton>
        </div>
      </Stack>

      <div
        className="small-font"
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div style={{ flex: 1 }}>Runway:</div>
        <div>
          <HelpfulLabel onClick={() => setShowRunwayModal(true)}>{pluralize(runway?.runwayInMonths, 'month')}</HelpfulLabel>
        </div>
      </div>

      <RunwayModal show={showRunwayModal} onHide={() => setShowRunwayModal(false)} />
    </Stack>
  );
}

export default DashboardGauge;
