import React from 'react';
import { Image, Stack } from 'react-bootstrap';
import useDocumentTitle from '../hooks/useDocumentTitle';
import BetterButton from '../components/BetterButton';
import { generateRoute, Route } from '../Routes';
import { useNavigate } from 'react-router-dom';
import { STEPS } from '../components/Welcome/Welcome';
import { BsChevronRight } from 'react-icons/bs';

function HomePage() {
  useDocumentTitle('Home');
  const navigate = useNavigate();

  function handleCallToActionClick() {
    navigate(generateRoute(Route.Welcome, { step: STEPS[0].path }));
  }

  function handleVideoClick(event: React.MouseEvent<HTMLVideoElement>) {
    const video = event.currentTarget;
    video.paused ? video.play() : video.pause();
  }

  return (
    <>
      <section className="hero">
        <div className="wrapper">
          <Stack gap={3}>
            <h1>Enjoy spending with certainty.</h1>
            <hr />
            <h1>Recover from the unexpected.</h1>
            <hr />
            <h1>Achieve financial independence.</h1>
          </Stack>
          <div className="call-to-action">
            <BetterButton afterIcon={<BsChevronRight />} onClick={handleCallToActionClick} variant="primary" size="lg">
              <strong>Get started</strong>
            </BetterButton>
          </div>
        </div>

        <div className="iphone-demo">
          <Image src="marcom/iphone-frame.png" alt="iPhone frame for video" />
          <video autoPlay muted playsInline loop onClick={handleVideoClick} poster="marcom/lopery-demo-reel-first-frame.jpg">
            {/* https://console.cloud.google.com/storage/browser/lopery-react.appspot.com?pageState=(%22StorageObjectListTable%22:(%22f%22:%22%255B%255D%22))&project=lopery-react */}
            <source src="https://storage.googleapis.com/lopery-react.appspot.com/lopery-demo-reel.mp4" type="video/mp4" />
          </video>
        </div>
      </section>
    </>
  );
}

export default HomePage;
