import { deleteField, doc, setDoc, Timestamp } from 'firebase/firestore';
import db, { recalculateBudgets } from '../firebase';
import { Dayjs } from 'dayjs';
import { TransactionDocData } from '../types';
import { FieldValue } from '@google-cloud/firestore';

interface UpdateTransactionData {
  name: string;
  nameOriginal?: string | FieldValue;
  date: Timestamp;
  dateOriginal?: Timestamp | FieldValue;
  updatedAt: Timestamp;
}

async function updateTransaction(uid: string, transaction: TransactionDocData, data: { name: string; date: Dayjs }) {
  const { transactionId } = transaction;

  const transactionDocRef = doc(db, 'users', uid, 'transactions', transactionId);

  const newName = data.name;
  const newDate = Timestamp.fromDate(data.date.toDate());

  const updateData: UpdateTransactionData = {
    name: newName,
    date: newDate,
    updatedAt: Timestamp.now(),
  };

  if (transaction.nameOriginal) {
    // If the nameOriginal is set but the new value for it is the same as the original name, delete it.
    if (transaction.nameOriginal === newName) updateData.nameOriginal = deleteField();
  } else {
    // If the nameOriginal is not set, set it if the new name is different from the original name.
    if (transaction.name !== newName) updateData.nameOriginal = transaction.name;
  }

  if (transaction.dateOriginal) {
    // Same logic as above.
    if (transaction.dateOriginal.isEqual(newDate)) updateData.dateOriginal = deleteField();
  } else {
    if (!transaction.date.isEqual(newDate)) updateData.dateOriginal = transaction.date;
  }

  setDoc(transactionDocRef, updateData, { merge: true });

  if (newDate.valueOf() !== transaction.date.valueOf())
    recalculateBudgets(newDate.valueOf() > transaction.date.valueOf() ? transaction.date.toDate() : data.date.toDate());
}

export { updateTransaction };
