import React from 'react';
import Highlighter from 'react-highlight-words';

interface TransactionNameProps {
  id: string;
  name: string;
  nameOriginal?: string;
  highlight?: string[];
}

function TransactionName({ id, name, nameOriginal, highlight = [] }: TransactionNameProps) {
  return (
    <span data-transaction-id={id} style={{ overflowWrap: 'anywhere' }} className="transaction-name" title={nameOriginal}>
      {highlight.length > 0 ? <Highlighter searchWords={highlight} autoEscape textToHighlight={name} /> : name}
    </span>
  );
}

export default TransactionName;
