import React from 'react';
import DollarAmount from '../DollarAmount';
import { ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PlanDocData } from '../../types';
import pluralize from '../../shared/pluralize';

interface PlanAccordionItemProps {
  plan: PlanDocData;
}

export default function PlanAccordionItem({ plan }: PlanAccordionItemProps) {
  return (
    <div data-testid={`plan-${plan.__id}`} data-plan-id={plan.__id} className="list-item-with-border py-3">
      <div className="d-flex justify-content-between" style={{ gap: 8 }}>
        <div style={{ flex: 1 }}>
          <Link to={`/${plan.transactionId ? 'recoveries' : 'goals'}/${plan.__id}`}>{plan.name}</Link>
        </div>
        <div>
          <DollarAmount amount={plan.installmentsSum} absolute />
          {!plan.isPerpetual && (
            <>
              {' '}
              <span className="text-muted small-font">of</span> <DollarAmount amount={plan.targetAmount} absolute />
            </>
          )}
        </div>
      </div>
      <div className="small-font text-muted mt-1">
        {plan.installmentsSum !== plan.targetAmount && (
          <>
            <DollarAmount absolute amount={plan.installmentAmount} color /> set aside each week{' '}
            {!plan.isPerpetual && (
              <>for {pluralize(Math.ceil((plan.targetAmount - plan.installmentsSum) / plan.installmentAmount), 'more week')}</>
            )}
          </>
        )}
      </div>
      {!plan.isPerpetual && (
        <div className="mt-2">
          <ProgressBar
            striped
            variant="success"
            now={(plan.installmentsSum / plan.targetAmount) * 100}
            style={{ height: 'calc(var(--bs-progress-height) / 2)' }}
          />
        </div>
      )}
    </div>
  );
}
