import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Stack } from 'react-bootstrap';
import Tip from './Tip';
import { RecurringExpensesExplanation } from '../pages/HelpPage';

export default function RecurringExpensesTip() {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleOpenModal = () => setShowModal(true);

  return (
    <>
      <Tip>
        Select expenses like rent/mortgage, insurance, utilities, gym memberships, child care, and subscription services like Netflix or
        Hulu (
        <Link onClick={handleOpenModal} to="#">
          more examples
        </Link>
        ). Ask yourself, “do I expect this expense to happen again even if I don’t do or buy anything?”
      </Tip>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Recurring expenses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack gap={3}>
            <RecurringExpensesExplanation />
          </Stack>
        </Modal.Body>
      </Modal>
    </>
  );
}
