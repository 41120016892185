import React, { HTMLAttributes } from 'react';

interface NoWrapProps extends HTMLAttributes<HTMLSpanElement> {
  children: React.ReactNode;
}

export default function NoWrap({ children, ...rest }: NoWrapProps) {
  return (
    <span style={{ whiteSpace: 'nowrap' }} {...rest}>
      {children}
    </span>
  );
}
