import React from 'react';
import { Accordion, Alert, Modal } from 'react-bootstrap';
import { useDashboard } from '../../contexts/DashboardContext';
import { useAuth } from '../../App';
import { Link } from 'react-router-dom';
import { Route } from '../../Routes';
import AverageSpendingAllowanceAccordionItem from './AverageSpendingAllowanceAccordionItem';
import TransactionsAccordionItem from './TransactionsAccordionItem';
import NetIncomeAccordionItem from './NetIncomeAllowanceAccordionItem';
import InstallmentsAccordionItem from './PlansAccordionItem';
import CarryoverAccordionItem from './CarryoverAccordionItem';
import SafeToSpendTotal from './SafeToSpendTotal';

export default function SafeToSpendModal() {
  const { userData } = useAuth();
  const { showBudgetModal, setShowBudgetModal } = useDashboard();

  return (
    <Modal show={showBudgetModal} onHide={() => setShowBudgetModal(false)} contentClassName="overflow-hidden">
      {/* 👆 Adding overflow-hidden preserves the rounded corners */}
      <Modal.Header closeButton>
        <Modal.Title>Safe to Spend</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 overflow-hidden">
        {userData?.isAllowanceBasedOnMovingAverage && (
          <Alert style={{ margin: `1rem 1.25rem` }}>
            {/* 👆 Margin matches the definitions of var(--bs-accordion-btn-padding-x/y) but sadly can't be reused because this is outside of the .accordion container */}
            You’ve configured your Safe to Spend to be based on average spending in <Link to={Route.Settings}>Settings</Link>. As a result,
            it does not account for recurring income, recurring expenses, plans, or carryover.
          </Alert>
        )}

        <Accordion flush>
          {userData?.isAllowanceBasedOnMovingAverage ? (
            <>
              <AverageSpendingAllowanceAccordionItem />
              <TransactionsAccordionItem />
            </>
          ) : (
            <>
              <NetIncomeAccordionItem />
              <InstallmentsAccordionItem />
              <CarryoverAccordionItem />
              <TransactionsAccordionItem />
            </>
          )}
        </Accordion>

        <SafeToSpendTotal />
      </Modal.Body>
    </Modal>
  );
}
