import React, { useState } from 'react';
import { Button, Stack } from 'react-bootstrap';
import DashboardGauge from './DashboardGauge';
import Loader from '../Loader';
import useBudget, { UseBudgetOptions } from '../../hooks/useBudget';
import useAccounts from '../../hooks/useAccounts';
import BudgetCalendar, { BudgetPeriods, Timescale } from '../../shared/BudgetCalendar';
import ErrorAlert from '../ErrorAlert';
import DashboardTimeRangeSelector from './DashboardTimeRangeSelector';
import { recalculateBudgets } from '../../firebase';
import BigSpinner from '../BigSpinner';
import NoContent from '../NoContent';
import { TransactionRows } from '../TransactionRow';
import SafeToSpendModal from '../SafeToSpendModal/SafeToSpendModal';
import DollarAmount from '../DollarAmount';
import { useAppLayout } from '../../layouts/AppLayout';
import useTransactions from '../../hooks/useTransactions';
import DashboardContext from '../../contexts/DashboardContext';
import { useAuth } from '../../App';
import BudgetView from '../../shared/BudgetView';
import { Runway } from '../../types';
import { BsEnvelopeOpenHeart } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { generateRoute, Route } from '../../Routes';
import { utcDay } from '../../shared/days';

type DashboardProps = {
  budgetPeriods: BudgetPeriods;
  timescale: Timescale;
};

function Dashboard({ budgetPeriods, timescale }: DashboardProps) {
  const { isDarkMode } = useAppLayout();
  const { userData } = useAuth();
  const navigate = useNavigate();

  const [isCreatingBudget, setIsCreatingBudget] = useState(false);
  const [showBudgetModal, setShowBudgetModal] = useState<boolean>(false);

  const { accountsById, error: accountsError, includedAccounts, isLoading: isLoadingAccounts } = useAccounts();

  const {
    transactions,
    isLoading: isLoadingTransactions,
    error: transactionsError,
  } = useTransactions({
    startDate: budgetPeriods.utilization.start,
    endDate: budgetPeriods.utilization.end,
    accounts: includedAccounts,
  });

  const useBudgetParams: UseBudgetOptions = {};

  if (timescale === Timescale.Week) useBudgetParams.weekEnd = budgetPeriods.utilization.end;
  else if (timescale === Timescale.Month) useBudgetParams.monthEnd = budgetPeriods.utilization.end;

  const {
    budget,
    error: budgetError,
    isLoading: isLoadingBudget,
  } = useBudget({
    ...useBudgetParams,
    onSnapshot: (snapshot) => {
      if (
        snapshot.empty &&
        !isCreatingBudget &&
        // This may be susceptible to a bug if the user has multiple empty weeks
        // in a row. We should consider a more robust solution.
        BudgetCalendar.isNowPeriod(budgetPeriods.utilization.start, timescale)
      ) {
        setIsCreatingBudget(true);
        recalculateBudgets(budgetPeriods.utilization.start.toDate()).catch();
      } else {
        setIsCreatingBudget(false);
      }
    },
  });

  let budgetView: BudgetView;
  let runway: Runway;

  if (budget) {
    if (timescale === Timescale.Month) {
      budgetView = new BudgetView(
        budget.monthAllowance,
        budget.monthRunway.averageUtilization,
        budget.monthUtilization,
        userData.isAllowanceBasedOnMovingAverage,
      );
      runway = budget.monthRunway;
    } else if (timescale === Timescale.Week) {
      budgetView = new BudgetView(
        budget.weekAllowance,
        budget.weekRunway.averageUtilization,
        budget.weekUtilization,
        userData.isAllowanceBasedOnMovingAverage,
      );
      runway = budget.weekRunway;
    }
  }

  // Keep this for debugging purposes, while we roll this out and troubleshoot issues
  console.log({
    timescale,
    budgetPeriods,
    budget,
    transactions,
    budgetView,
  });

  return (
    <DashboardContext.Provider
      value={{
        accountsById,
        budget,
        budgetPeriods,
        budgetView,
        includedAccounts,
        isCreatingBudget,
        runway,
        setShowBudgetModal,
        showBudgetModal,
        timescale,
        transactions,
      }}
    >
      <Loader isLoading={isLoadingAccounts || isLoadingBudget || isLoadingTransactions || isCreatingBudget} />

      <ErrorAlert error={accountsError} />
      <ErrorAlert error={budgetError} />
      <ErrorAlert error={transactionsError} />

      <Stack gap={2}>
        <section>
          <DashboardTimeRangeSelector />
        </section>

        <section className="dashboard-gauge-container">{!isCreatingBudget && <DashboardGauge />}</section>

        <section>
          {isCreatingBudget && <BigSpinner />}

          {transactions.length === 0 && !isCreatingBudget && <NoContent>No transactions</NoContent>}

          <TransactionRows transactions={transactions} accountsById={accountsById} endingBalance={budgetView?.remainder} />
        </section>

        <section className="dashboard-footer">
          <Button variant={isDarkMode ? 'dark' : 'light'} className="budget-button" onClick={() => setShowBudgetModal(true)}>
            <Stack direction="horizontal" gap={2}>
              <BsEnvelopeOpenHeart />
              <strong>Allowance</strong>
            </Stack>
            <div>
              <DollarAmount amount={budgetView?.allowance} per={timescale} color />
            </div>
          </Button>
        </section>
      </Stack>

      <SafeToSpendModal />
    </DashboardContext.Provider>
  );
}

export default Dashboard;
