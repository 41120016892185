import React from 'react';
import { Container, Col, Row, Alert } from 'react-bootstrap';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { BsClipboardData, BsCompass, BsJournalBookmarkFill } from 'react-icons/bs';
import { useAuth } from '../App';
import { Route } from '../Routes';
import UserDropdownMenu from './UserDropdownMenu';

function getClassNameForNavLink({ isActive }) {
  return `${isActive ? 'active' : ''}`.trimEnd();
}

export default function Header() {
  const { user, isOnboarding, impersonating } = useAuth();
  const { pathname } = useLocation();

  return (
    <header>
      {impersonating && <Alert variant="warning">You are impersonating {user.email}.</Alert>}

      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col xs={2} className="align-self-center">
            <Link to={Route.Root}>
              <img
                src="/logo.svg"
                width="100"
                height="24"
                alt="Lopery logo, which is money in an envelope"
                className="logo"
                style={{ display: 'block' }}
              />
            </Link>
          </Col>
          <Col sm={1} xs={10} className="order-sm-last">
            <UserDropdownMenu />
          </Col>
          <Col sm={9} xs={12}>
            {!isOnboarding && (
              <nav>
                <NavLink className={getClassNameForNavLink} to={Route.Root}>
                  <BsCompass /> <span>Budget</span>
                </NavLink>
                <NavLink
                  className={pathname.startsWith(Route.Recoveries) || pathname.startsWith(Route.Goals) ? 'active' : ''}
                  to={Route.Recoveries}
                >
                  <BsClipboardData /> <span>Plans</span>
                </NavLink>
                <NavLink className={getClassNameForNavLink} to={Route.Transactions}>
                  <BsJournalBookmarkFill /> <span>Ledger</span>
                </NavLink>
              </nav>
            )}
          </Col>
        </Row>
      </Container>
    </header>
  );
}
