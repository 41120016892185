import React from 'react';

function Item({ icon = null, style = {}, gap = 12, children }) {
  return (
    <li>
      <div
        style={{
          display: 'flex',
          gap,
          ...style,
        }}
      >
        {icon && <div>{icon}</div>}
        <div>{children}</div>
      </div>
    </li>
  );
}
function List({ children, style = {} }) {
  return (
    <ul
      style={{
        listStyle: 'none',
        padding: 0,
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
        ...style,
      }}
    >
      {children}
    </ul>
  );
}

List.Item = Item;
export default List;
