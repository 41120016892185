import React, { useState } from 'react';
import { Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { BsCalendar2Check } from 'react-icons/bs';
import { Timestamp } from 'firebase/firestore';
import { shuffle } from 'underscore';
import { recalculateBudgets } from '../../firebase';
import { useAuth } from '../../App';
import Tip from '../Tip';
import DollarAmount from '../DollarAmount';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Route } from '../../Routes';
import { PlanDocData } from '../../types';
import BudgetCalendar, { Timescale } from '../../shared/BudgetCalendar';
import { utcDay } from '../../shared/days';
import { createPlan } from '../../utils/plan';
import BetterButton from '../BetterButton';

export default function GoalModalForm({ showModal, setShowModal }: { showModal: boolean; setShowModal: (show: boolean) => void }) {
  const { uid } = useAuth();
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [nameIsValid, setNameIsValid] = useState(true);
  const [targetAmount, setTargetAmount] = useState<number | undefined>();
  const [targetAmountIsValid, setTargetAmountIsValid] = useState(true);
  const [installmentAmount, setInstallmentAmount] = useState<number | undefined>();
  const [installmentAmountIsValid, setInstallmentAmountIsValid] = useState(true);
  const [isPerpetual, setIsPerpetual] = useState(true);
  const [inspiration] = useState(shuffle(['Vacation to Mexico', 'Buy a new car', 'Build a safety net', 'New TV'])[0]);
  const [isLoading, setIsLoading] = useState(false);

  const start = BudgetCalendar.getBudgetPeriodsByDate(Timescale.Week, utcDay()).utilization.start;

  function clear() {
    setTargetAmountIsValid(true);
    setInstallmentAmountIsValid(true);
    setNameIsValid(true);
    setName('');
    setTargetAmount(undefined);
    setInstallmentAmount(undefined);
    setIsPerpetual(true);
  }

  async function handleCreate() {
    setIsLoading(true);

    const newInstallmentAmountIsValid = installmentAmount !== undefined && installmentAmount > 0;
    const newTargetAmountIsValid = isPerpetual ? true : targetAmount !== undefined && targetAmount > 0 && targetAmount >= installmentAmount;
    const newNameIsValid = name !== undefined && name !== '';

    setInstallmentAmountIsValid(newInstallmentAmountIsValid);
    setTargetAmountIsValid(newTargetAmountIsValid);
    setNameIsValid(newNameIsValid);

    if (!newInstallmentAmountIsValid || !newTargetAmountIsValid || !newNameIsValid) {
      setIsLoading(false);
      return;
    }

    const planData: PlanDocData = {
      createdAt: Timestamp.now(),
      installmentAmount,
      installmentsSum: 0,
      isPerpetual,
      name,
      start: Timestamp.fromDate(start.toDate()),
      updatedAt: Timestamp.now(),
    };

    if (!isPerpetual) planData.targetAmount = targetAmount;

    await createPlan(uid, planData);

    clear();
    setIsLoading(false);
    setShowModal(false);
    navigate(Route.Goals);
  }

  function handleIsPerpetualChange() {
    setIsPerpetual(!isPerpetual);

    if (!isPerpetual) {
      setTargetAmount(undefined);
      setTargetAmountIsValid(true);
    }
  }

  function handleNameChange(event) {
    setName(event.target.value);
    setNameIsValid(true);
  }

  function handleInstallmentAmountChange(event) {
    setInstallmentAmount(event.target.value ? Number(event.target.value) : undefined);
    setInstallmentAmountIsValid(true);
  }

  function handleTargetAmountChange(event) {
    setTargetAmount(event.target.value ? Number(event.target.value) : undefined);
    setTargetAmountIsValid(true);
  }

  return (
    <Modal
      show={showModal}
      onHide={() => {
        clear();
        setShowModal(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create a new goal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Creating a goal sets aside money from your allowance each week. At any time, you can close & cash in the goal and the money you’ve
          saved will be added to your Safe to Spend.
        </p>
        <Form>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column xs="3" htmlFor="plan-name">
              Name
            </Form.Label>
            <Col xs="9">
              <Form.Control
                required
                isInvalid={!nameIsValid}
                type="text"
                placeholder={`e.g. ${inspiration}`}
                value={name}
                onChange={handleNameChange}
                autoComplete="off"
                id="plan-name"
                maxLength={50}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column xs={6} className="mb-6" htmlFor="plan-installment-amount">
              Weekly contribution
            </Form.Label>
            <Col xs="6">
              <InputGroup className="mb-2">
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  required
                  isInvalid={!installmentAmountIsValid}
                  type="number"
                  value={installmentAmount}
                  onChange={handleInstallmentAmountChange}
                  id="plan-installment-amount"
                />
              </InputGroup>
            </Col>
          </Form.Group>
          <Form.Group className="mb-3">
            <div className="d-flex justify-content-between">
              <Form.Label htmlFor="plan-is-perpetual">Set a target</Form.Label>
              <Form.Switch checked={!isPerpetual} onChange={handleIsPerpetualChange} id="plan-is-perpetual" />
            </div>
          </Form.Group>
          {!isPerpetual && (
            <Form.Group as={Row} className="mb-3">
              <Form.Label column xs="6">
                Target amount
              </Form.Label>
              <Col xs="6" className="">
                <InputGroup className="mb-2">
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    required
                    isInvalid={!targetAmountIsValid}
                    type="number"
                    value={targetAmount}
                    onChange={handleTargetAmountChange}
                  />
                </InputGroup>
              </Col>
            </Form.Group>
          )}
        </Form>
        {installmentAmount && (
          <Tip icon={<BsCalendar2Check />}>
            Every week, <DollarAmount amount={installmentAmount} keepSign /> will be deducted from your spending limit and added to this
            goal
            {!isPerpetual && targetAmount && (
              <>
                {' '}
                until you reach your target of <DollarAmount amount={targetAmount} keepSign /> on{' '}
                {dayjs()
                  .add(targetAmount / installmentAmount, 'weeks')
                  .format('MMM D, YYYY')}
              </>
            )}
            .
          </Tip>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <BetterButton isLoading={isLoading} hideLoadingIndicators variant="link" onClick={clear}>
          Reset
        </BetterButton>
        <BetterButton isLoading={isLoading} variant="primary" onClick={handleCreate}>
          Create goal
        </BetterButton>
      </Modal.Footer>
    </Modal>
  );
}
