import React from 'react';
import { Stack } from 'react-bootstrap';
import { BsWrenchAdjustable } from 'react-icons/bs';

export default function Maintenance() {
  return (
    <Stack
      dir="vertical"
      gap={3}
      className="d-flex justify-content-center text-center vh-100"
    >
      <div style={{ fontSize: '3em', filter: 'invert(0.5)' }}>
        <BsWrenchAdjustable />
      </div>
      <h1>Be right back!</h1>
      <div>
        Please sit tight. Lopery is undergoing some maintenance and will be
        online in a few minutes.
      </div>
    </Stack>
  );
}
