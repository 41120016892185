import React from 'react';
import { BsCheckCircleFill } from 'react-icons/bs';
import { Stack } from 'react-bootstrap';
import ConfettiExplosion from 'react-confetti-explosion';
import AllowanceTable from './AllowanceTable';
import DollarAmount from '../DollarAmount';
import useBudget from '../../hooks/useBudget';

export default function BudgetCompleteStep() {
  const { budget } = useBudget();

  return (
    <Stack gap={4}>
      <div style={{ textAlign: 'center' }}>
        <div
          style={{
            fontSize: '3em',
            lineHeight: 1,
            marginBottom: '0.2em',
            color: 'var(--bs-success)',
          }}
          className="animate__animated animate__bounceIn"
        >
          <BsCheckCircleFill />
          <div style={{ margin: '0 auto', width: 0, height: 0 }}>
            <ConfettiExplosion duration={5000} particleCount={60} />
          </div>
        </div>
        <strong>
          Your allowance for this week is <DollarAmount amount={budget?.weekAllowance} />!
        </strong>
      </div>

      <div>
        Now, you’re ready to start using Lopery to track your spending. Lopery automatically downloads new transactions and subtracts your
        spending from your allowance.
      </div>

      <div>
        The resulting number is your <strong>Safe to Spend</strong> and as long as this number stays positive, you’ll be always be able to
        cover your recurring expenses and any new credit card charges. What’s left of your Safe to Spend will automatically carry over.
      </div>

      <AllowanceTable />
    </Stack>
  );
}
