import React from 'react';
import NoWrap from './NoWrap';

export default function TransactionPending() {
  return (
    <NoWrap>
      <span style={{ color: 'var(--bs-warning)' }}>●</span> Pending
    </NoWrap>
  );
}
