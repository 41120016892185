import React from 'react';
import { useState } from 'react';
import { getImpersonationToken, impersonate } from '../firebase';
import { Alert, Form } from 'react-bootstrap';
import BetterButton from './BetterButton';

export const Storage = Object.freeze({
  Impersonating: 'impersonating',
});

export default function Impersonation() {
  const [error, setError] = useState(null);
  const [impersonationUid, setImpersonationUid] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit() {
    setIsLoading(true);

    try {
      const impersonationTokenResponse: { data: any } = await getImpersonationToken({
        impersonationUid,
      });
      const {
        data: { impersonationToken },
      } = impersonationTokenResponse;
      if (impersonationToken) {
        await impersonate(impersonationToken);
        sessionStorage.setItem(Storage.Impersonating, 'true');
        return (window.location.href = '/');
      } else {
        setError('No token was returned');
      }
    } catch (e) {
      return setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}

      <Form>
        <Form.Group className="mb-3">
          <Form.Label>UID</Form.Label>
          <Form.Control
            onChange={(e) => {
              setImpersonationUid(e.target.value);
            }}
            type="text"
            placeholder="e.g., LyCjZeEVplqhe1LhLMVddXYQZSI5"
          />
          <Form.Text className="text-muted">
            Never impersonate someone without their written consent. All requests to impersonate another user are logged for auditting
            purposes.
          </Form.Text>
        </Form.Group>
        <BetterButton onClick={handleSubmit} isLoading={isLoading}>
          Impersonate
        </BetterButton>
      </Form>
    </>
  );
}
