import React, { forwardRef, useCallback } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from 'react-icons/bs';
import { Timescale } from '../../shared/BudgetCalendar';
import { useAuth } from '../../App';
import { collection, limit, orderBy, query } from 'firebase/firestore';
import db from '../../firebase';
import useOnSnapshot from '../../hooks/useOnSnapshot';
import { utcDay } from '../../shared/days';
import { useDashboard } from '../../contexts/DashboardContext';
import { useNavigate } from 'react-router-dom';
import { BudgetDocData } from '../../types';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const WeekMonthToggle = forwardRef<HTMLButtonElement, React.HTMLProps<HTMLButtonElement>>(({ children, onClick }, ref) => (
  <Button
    variant="link"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="small-font text-muted"
  >
    {children}
  </Button>
));

export default function DashboardTimeRangeSelector() {
  const { uid } = useAuth();
  const { timescale, budgetPeriods } = useDashboard();
  const navigate = useNavigate();

  const periodEnd = budgetPeriods.utilization.end;
  const periodStart = budgetPeriods.utilization.start;

  const oldestBudgetQuery = query(collection(db, 'users', uid, 'budgets'), orderBy('weekStart', 'asc'), limit(1));

  const { docs: oldestBudgets, isLoading: isOldestBudgetSnapshotLoading } = useOnSnapshot<BudgetDocData>(oldestBudgetQuery);

  let prevPeriodDisabled = true;

  if (!isOldestBudgetSnapshotLoading && oldestBudgets.length > 0) {
    const oldestBudgetDate = utcDay(oldestBudgets[0].weekEnd.toDate());
    prevPeriodDisabled = oldestBudgetDate.isAfter(periodStart);
  }

  const navigateToPeriod = useCallback(
    (timescale, newPeriodStart) => {
      navigate(`/${timescale}/${newPeriodStart.format('YYYY-MM-DD')}`);
    },
    [navigate],
  );

  const navigateToPrevPeriod = useCallback(() => {
    navigateToPeriod(timescale, periodStart.subtract(1, timescale));
  }, [timescale, periodStart, navigateToPeriod]);

  const navigateToNextPeriod = useCallback(() => {
    navigateToPeriod(timescale, periodStart.add(1, timescale));
  }, [timescale, periodStart, navigateToPeriod]);

  const navigateToTimescale = useCallback(
    (newTimescale) => {
      navigateToPeriod(newTimescale, periodStart);
    },
    [periodStart, navigateToPeriod],
  );

  const now = utcDay();

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div>
        <Button variant="link" disabled={prevPeriodDisabled} onClick={navigateToPrevPeriod}>
          <BsFillArrowLeftCircleFill />
        </Button>
      </div>
      <div style={{ flex: 1, textAlign: 'center' }}>
        <Dropdown drop="down-centered" onSelect={navigateToTimescale}>
          <Dropdown.Toggle as={WeekMonthToggle}>
            {timescale === Timescale.Week && 'Week of'}
            {timescale === Timescale.Month && 'Month of'}:
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey={Timescale.Week} active={timescale === Timescale.Week}>
              View by week
            </Dropdown.Item>
            <Dropdown.Item eventKey={Timescale.Month} active={timescale === Timescale.Month}>
              View by month
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div>
          {timescale === Timescale.Week && (
            <>
              {periodStart.format('ddd, MMM D')} - {periodEnd.format('ddd, MMM D')}
            </>
          )}
          {timescale === Timescale.Month && <>{periodStart.format('MMMM')}</>}
        </div>
      </div>
      <div>
        <Button variant="link" disabled={periodStart.isBefore(now) && periodEnd.isAfter(now)} onClick={navigateToNextPeriod}>
          <BsFillArrowRightCircleFill />
        </Button>
      </div>
    </div>
  );
}
