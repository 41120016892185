import React from 'react';
import { useEffect, useState } from 'react';
import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore';
import { useAuth } from '../App';
import dayjs from 'dayjs';
import useTransactions from '../hooks/useTransactions';
import Loader from './Loader';
import pluralize from '../shared/pluralize';
import { TransactionRows } from '../components/TransactionRow';
import { Stack } from 'react-bootstrap';
import useAccounts from '../hooks/useAccounts';
import db from '../firebase';
import BigSpinner from './BigSpinner';
import { TransactionDownloadDocData, TransactionFilters } from '../types';
import { generateRoute, Route } from '../Routes';
import { useNavigate } from 'react-router-dom';
import TransactionFilter from './TransactionFilter';

export default function Transactions() {
  const { uid } = useAuth();
  const navigate = useNavigate();

  const [latestTransactionDownload, setLatestTransactionDownload] = useState<TransactionDownloadDocData>();
  const [filters, setFilters] = useState<TransactionFilters>({});
  const { accountsById } = useAccounts();
  const { transactions, isLoading, filterNameWords } = useTransactions({ filters });

  useEffect(() => {
    getLatestTransactionDownload().catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getLatestTransactionDownload() {
    const querySnapshot = await getDocs(
      query(collection(db, 'users', uid, 'transactionDownloads'), orderBy('createdAt', 'desc'), limit(1)),
    );

    if (querySnapshot.size === 1) setLatestTransactionDownload(querySnapshot.docs[0].data() as TransactionDownloadDocData);
  }

  return (
    <>
      <Loader isLoading={isLoading} />

      {isLoading ? (
        <BigSpinner />
      ) : (
        <Stack gap={4}>
          <section>
            <TransactionFilter setFilters={setFilters} />

            <div className="d-flex justify-content-between small-font text-muted mt-2">
              <div className="cap-first-letter">{pluralize(transactions.length, 'transaction')}</div>
              {latestTransactionDownload && (
                <div>As of {dayjs(latestTransactionDownload.createdAt.toDate()).format('MMM D, YYYY [at] h:mma')}</div>
              )}
            </div>
          </section>

          <section>
            <TransactionRows
              highlightNameWords={filterNameWords}
              accountsById={accountsById}
              transactions={transactions}
              onTransactionUpdate={({ transactionId }) =>
                navigate(
                  generateRoute(Route.Transaction, {
                    transactionId,
                  }),
                )
              }
            />
          </section>
        </Stack>
      )}
    </>
  );
}
