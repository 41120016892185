import React from 'react';
import BetterAccordionItem from '../BetterAccordionItem';
import DollarAmount from '../DollarAmount';
import ListSection, { ListSectionItem } from './ListSection';
import sumAmounts from '../../utils/sumAmounts';
import { useDashboard } from '../../contexts/DashboardContext';
import useTransactions from '../../hooks/useTransactions';
import { TransactionRowMetadata } from '../TransactionRow';

export default function TransactionsAccordionItem() {
  const { timescale, budgetPeriods, accountsById, includedAccounts } = useDashboard();

  const {
    error,
    isLoading,
    transactions: allTransactions,
  } = useTransactions({
    startDate: budgetPeriods.utilization.start,
    endDate: budgetPeriods.utilization.end,
    accounts: includedAccounts,
    orderByDirection: 'asc',
  });

  const transactions = allTransactions.filter(({ planId, isRecurring, isIgnored }) => !isRecurring && !isIgnored && !planId);

  return (
    <BetterAccordionItem
      key="transactions"
      eventKey="transactions"
      header={`Transactions for the ${timescale}`}
      secondLine={`The sum of all transaction amounts not including ignored, recovered, or recurring`}
      secondColumn={<DollarAmount amount={sumAmounts(transactions)} showDecimals keepSign color />}
      error={error}
      isLoading={isLoading}
    >
      <ListSection isLoading={isLoading}>
        {transactions.map((transaction) => (
          <ListSectionItem
            key={transaction.transactionId}
            name={transaction.name}
            value={<DollarAmount amount={transaction.amount} showDecimals color />}
            detail={<TransactionRowMetadata transaction={transaction} account={accountsById[transaction.accountId]} />}
          />
        ))}
      </ListSection>
    </BetterAccordionItem>
  );
}
