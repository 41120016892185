interface Interogable {
  amount?: number;
}

export function isTransactionExpense<T extends Interogable>(intergoable: T) {
  return intergoable.amount !== undefined && intergoable.amount >= 0;
}

export function isTransactionIncome<T extends Interogable>(intergoable: T) {
  return intergoable.amount !== undefined && intergoable.amount < 0;
}
