import React from 'react';
import { useEffect, useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { reconnectInstitution, connectInstitution, getLinkTokens, recalculateBudgets } from '../firebase';
import PlaidLinkButton from './PlaidLinkButton';
import { groupBy, sortBy } from 'underscore';
import { Stack, Alert, Form, Accordion, Spinner } from 'react-bootstrap';
import { BsPlusCircle, BsFillExclamationCircleFill } from 'react-icons/bs';
import pluralize from '../shared/pluralize';
import Loader from './Loader';
import { ComponentsSeparatedByDots } from './DotSeparator';
import RemovePlaidItem from './RemovePlaidItem';
import { trackMetric, Metric } from '../utils/metrics';
import useAccounts from '../hooks/useAccounts';
import ErrorAlert from './ErrorAlert';
import { useAppLayout } from '../layouts/AppLayout';
import DollarAmount from './DollarAmount';
import BetterAccordionItem from './BetterAccordionItem';
import { PlaidInstitution } from 'react-plaid-link';
import { LinkTokenResponseData } from '../types';

export default function Accounts() {
  const {
    plaidItemsForUpdate,
    plaidItemsForUpdateSnapshotSubscribe,
    plaidItemsForUpdateSnapshotUnsubscribe,
    isLoadingPlaidItemsForUpdate,
    plaidItemsForUpdateOnSnapshotError,
  } = useAppLayout();

  const [linkTokens, setLinkTokens] = useState<unknown>({});
  const [isLoadingLinkTokens, setIsLoadingLinkTokens] = useState(false);
  const [isLoadingReconnectInstitution, setIsLoadingReconnectInstitution] = useState(false);
  const [linkTokensError, setLinkTokensError] = useState();
  const [connectInstitutionError, setConnectInstitutionError] = useState();
  const [reconnectInstitutionError, setReconnectInstitutionError] = useState();
  const [removedInstitution, setRemovedInstitution] = useState<PlaidInstitution>();
  const [newlyAddedAccounts, setNewlyAddedAccounts] = useState([]);

  const {
    accounts: accountsFromDb,
    subscribe: accountsSubscribe,
    unsubscribe: accountsUnsubscribe,
    isLoading: isLoadingAccounts,
    collectionRef: accountsCollectionRef,
    error: accountsError,
  } = useAccounts(() => {
    // Clear out the temporary list of accounts used to optimistically
    // update the UI since the back-end has them now.
    setNewlyAddedAccounts([]);
  });

  function unsubscribe() {
    accountsUnsubscribe();
    plaidItemsForUpdateSnapshotUnsubscribe();
  }

  function subscribe() {
    accountsSubscribe();
    plaidItemsForUpdateSnapshotSubscribe();
  }

  async function loadLinkTokens() {
    try {
      setIsLoadingLinkTokens(true);
      const linkTokenResponse = await getLinkTokens();
      setLinkTokens(linkTokenResponse.data);
    } catch (error) {
      setLinkTokensError(error);
    } finally {
      setIsLoadingLinkTokens(false);
    }
  }

  useEffect(() => {
    trackMetric(Metric.ACCOUNTS_PAGE_VISITED);
    loadLinkTokens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLinkTokenSuccess = async (publicToken, metadata) => {
    trackMetric(Metric.ACCOUNT_CONNECTED, {
      numAccounts: accountsFromDb.length,
    });

    // Optimistically update the UI, while the back-end works on downloading
    // the accounts and until onSnapshot fires next.
    setNewlyAddedAccounts(
      metadata.accounts.map(({ id, name, mask }) => ({
        isNewlyAdded: true,
        accountId: id,
        name,
        mask,
        isExcluded: true,
        institution: {
          institutionId: metadata.institution.institution_id,
          name: metadata.institution.name,
        },
      })),
    );

    try {
      await connectInstitution({ publicToken });
    } catch (error) {
      setConnectInstitutionError(error);
      setNewlyAddedAccounts([]);
    }
  };

  const handleLinkTokenForUpdateSuccess = async (plaidItemId) => {
    trackMetric(Metric.ACCOUNT_RECONNECTED);

    try {
      setIsLoadingReconnectInstitution(true);
      await reconnectInstitution({ plaidItemId });
    } catch (error) {
      setReconnectInstitutionError(error);
    } finally {
      setIsLoadingReconnectInstitution(false);
    }
  };

  const handleExcludedToggle = async (accountId, checked) => {
    trackMetric(checked ? Metric.ACCOUNT_INCLUDED : Metric.ACCOUNT_EXCLUDED);
    const accountDocRef = doc(accountsCollectionRef, accountId);
    await updateDoc(accountDocRef, { isExcluded: !checked });
    recalculateBudgets();
  };

  // If we don't unsubscribe, the RemovePlaidItem modal will disappear
  // as a result of deletion. Once the modal is closed, we can then re-subscribe,
  // which will remove the account (and the modal) from the DOM.
  const handleRemoveSuccess = (institution: PlaidInstitution) => {
    subscribe();
    setRemovedInstitution(institution);
    trackMetric(Metric.INSTITUTION_DELETED, { institution });
  };

  const accountsByPlaidItemId = groupBy([...accountsFromDb, ...newlyAddedAccounts], (account) => account.plaidItemId);

  const plaidItemIds = Object.keys(accountsByPlaidItemId);

  const { linkTokenForNewPlaidItem, linkTokensForUpdateByPlaidItemId } = linkTokens as LinkTokenResponseData;

  return (
    <>
      <Loader
        isLoading={
          isLoadingLinkTokens ||
          isLoadingAccounts ||
          isLoadingPlaidItemsForUpdate ||
          isLoadingReconnectInstitution ||
          newlyAddedAccounts.length > 0
        }
      />

      <ErrorAlert error={accountsError} />
      <ErrorAlert error={connectInstitutionError} />
      <ErrorAlert error={linkTokensError} />
      <ErrorAlert error={plaidItemsForUpdateOnSnapshotError} />
      <ErrorAlert error={reconnectInstitutionError} />

      {removedInstitution && (
        <Alert variant="success" onClose={() => setRemovedInstitution(null)} dismissible>
          Lopery no longer has access to {removedInstitution.name} and all associated data has been removed as you requested.
        </Alert>
      )}

      <Stack gap={4}>
        <section>
          <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
            <div>
              <PlaidLinkButton
                variant={accountsFromDb.length > 0 ? 'outline-primary' : 'primary'}
                linkToken={linkTokenForNewPlaidItem}
                onSuccess={handleLinkTokenSuccess}
                icon={<BsPlusCircle />}
              >
                Add account
              </PlaidLinkButton>
            </div>
            <div className="small-font text-muted">🔐 Lopery cannot access your money. Delete at any time.</div>
          </div>
        </section>

        {plaidItemIds.length > 0 && (
          <Accordion alwaysOpen>
            {plaidItemIds
              .sort((a, b) => {
                var institutionNameA = accountsByPlaidItemId[a][0].institution.name.toUpperCase();
                var institutionNameB = accountsByPlaidItemId[b][0].institution.name.toUpperCase();
                return institutionNameA < institutionNameB ? -1 : institutionNameA > institutionNameB ? 1 : 0;
              })
              .map((plaidItemId, i) => {
                const plaidItemForUpdate = plaidItemsForUpdate.filter(({ itemId }) => itemId === plaidItemId)[0];

                const needsUpdate = plaidItemForUpdate && linkTokensForUpdateByPlaidItemId;
                const accounts = accountsByPlaidItemId[plaidItemId];
                const includedAccounts = accounts.filter((account) => !account.isExcluded);
                const numAccountsAreLoading = accounts.filter((account) => account.isNewlyAdded).length;
                const institution = accounts[0].institution;
                const accountsWithBalances = includedAccounts.filter((account) => account.currentBalance !== undefined);
                const isLoading = numAccountsAreLoading > 0;

                return (
                  <BetterAccordionItem
                    key={plaidItemId}
                    eventKey={`${i}`}
                    data-plaid-item-id={plaidItemId}
                    header={institution.name}
                    icon={needsUpdate && <BsFillExclamationCircleFill color="var(--bs-danger)" />}
                    isLoading={isLoading}
                    secondLine={
                      needsUpdate
                        ? 'We are having trouble connecting to your bank'
                        : numAccountsAreLoading > 0
                        ? `Loading ${pluralize(numAccountsAreLoading, 'account')}…`
                        : `${pluralize(includedAccounts.length, 'account')} included in your budget`
                    }
                    secondColumn={
                      needsUpdate ? (
                        <PlaidLinkButton
                          linkToken={linkTokensForUpdateByPlaidItemId[plaidItemForUpdate.itemId]}
                          onSuccess={() => handleLinkTokenForUpdateSuccess(plaidItemForUpdate.itemId)}
                          variant="danger"
                          size="sm"
                        >
                          Reconnect
                        </PlaidLinkButton>
                      ) : isLoading ? (
                        <Spinner size="sm" />
                      ) : (
                        accountsWithBalances.length > 0 && (
                          <DollarAmount
                            amount={accountsWithBalances.reduce((balance, account) => {
                              balance += account.currentBalance;
                              return balance;
                            }, 0)}
                            color
                          />
                        )
                      )
                    }
                  >
                    {sortBy(accounts, 'name').map(({ accountId, isExcluded, name, mask, isNewlyAdded, currentBalance }) => (
                      <div
                        key={accountId}
                        className="account"
                        style={{
                          marginBottom: '.6em',
                          paddingBottom: '.6em',
                          borderBottom: '1px solid var(--bs-border-color)',
                        }}
                      >
                        <div style={{ display: 'flex' }}>
                          <div style={{ flex: 1 }}>{name}</div>
                          <Form.Check
                            key={accountId}
                            type="switch"
                            checked={!isExcluded}
                            disabled={isNewlyAdded}
                            onChange={isNewlyAdded ? null : ({ target: { checked } }) => handleExcludedToggle(accountId, checked)}
                            id={`account-${accountId}-is-excluded-switch`}
                            style={{ margin: 0 }}
                          />
                        </div>
                        <div className="small-font text-muted">
                          <ComponentsSeparatedByDots
                            components={[
                              mask && `x${mask}`,
                              !isExcluded && currentBalance !== undefined && <DollarAmount amount={currentBalance} />,
                              isExcluded && 'Excluded from your budget',
                            ]}
                          />
                        </div>
                      </div>
                    ))}
                    <RemovePlaidItem
                      institution={institution}
                      onConfirm={unsubscribe}
                      onSuccess={handleRemoveSuccess}
                      onCancel={subscribe}
                      plaidItemId={plaidItemId}
                    />
                  </BetterAccordionItem>
                );
              })}
          </Accordion>
        )}
      </Stack>
    </>
  );
}
