import React from 'react';
import { Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function PrivacyPage() {
  return (
    <div className="wrapper">
      <section className="floating-card">
        <Stack gap={3}>
          <h1>Privacy</h1>

          <div>
            This privacy notice discloses the privacy practices for this website. This privacy notice applies solely to information
            collected by this website. It will notify you of the following:
          </div>

          <ul style={{ marginBottom: 0 }}>
            <li>
              What personally identifiable information is collected from you through the website, how it is used and with whom it may be
              shared
            </li>
            <li>What choices are available to you regarding the use of your data</li>
            <li>The security procedures in place to protect the misuse of your information</li>
            <li>How you can correct any inaccuracies in the information</li>
            <li>Information collection, use, and sharing</li>
          </ul>

          <div>
            We are the sole owners of the information collected on this site. We only have access to/collect information that you
            voluntarily give us via email, direct contact from you, or from the bank integrations you establish. We will not sell or rent
            this information to anyone.
          </div>

          <div>
            We will not share your information with any third party outside of our organization, other than as necessary to provide support
            to you as a user.
          </div>

          <div>
            Unless you ask us not to, we may contact you via email in the future to tell you about specials, new products or services, or
            changes to this privacy policy.
          </div>

          <hr />

          <h3>Control Over Information</h3>

          <div>
            You may opt-out of any future contacts from us at any time. You may request that your account and associated data be deleted at
            any time by emailing <a href="mailto:support@lopery.com">support@lopery.com</a>.
          </div>

          <hr />

          <h3>What we store</h3>

          <div>
            We store information about the accounts you connect to our system. This information is used to help us classify transactions.
          </div>

          <div>We do not sell your data, nor do we sell information about you to partners or affiliates.</div>

          <div>Below is a list of the information we store:</div>

          <ul style={{ marginBottom: 0 }}>
            <li>Account balances and the last four digits of your account numbers</li>
            <li>The name of the institution to which you authorize us to connect</li>
            <li>Up to two years worth of transactions for each account you connect</li>
            <li>Transaction data includes the date, amount, category, and description</li>
            <li>Your name, email, profile picture, and timezone</li>
          </ul>

          <div>
            Your email address is used to communicate with you and to notify you of information we consider valuable. Our system
            periodically sends you an email as your budget changes, which include a short list of recent transactions. We do not and will
            not ever sell your information to another party.
          </div>

          <hr />

          <h3>Bank integrations</h3>

          <div>
            We use{' '}
            <Link to="https://plaid.com/why-is-plaid-involved/" target="_blank">
              Plaid
            </Link>{' '}
            to integrate with your financial institution. Plaid powers over 7,000 apps and services and integrates with over 12,000
            financial institutions. Plaid has been independently verified to use bank-grade security to protect and store your login
            information.
          </div>
        </Stack>
      </section>
    </div>
  );
}
