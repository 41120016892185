import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import db, { recalculateBudgets } from '../firebase';
import { Metric, trackMetric } from './metrics';
import { removePlan } from './plan';
import { PlanDocData } from '../types';

export default async function toggleTransactionField(uid, transaction, field) {
  const transactionDocRef = doc(db, 'users', uid, 'transactions', transaction.transactionId);

  const newValue = !transaction[field];
  const updateData = {
    isIgnored: false,
    isRecurring: false,
    isRecurringSetByUser: field === 'isRecurring',
    [field]: newValue,
    updatedAt: serverTimestamp(),
  };

  if (updateData.isRecurring) trackMetric(Metric.TRANSACTION_MARKED_RECURRING);
  if (updateData.isIgnored) trackMetric(Metric.TRANSACTION_MARKED_IGNORED);

  if ((field === 'isRecurring' || field === 'isIgnored') && !!transaction.planId)
    await removePlan(uid, {
      __id: transaction.planId,
      transactionId: transaction.transactionId,
      start: transaction.date,
    } as PlanDocData);

  await setDoc(transactionDocRef, updateData, { merge: true });
  recalculateBudgets(transaction.date.toDate()).catch();

  return newValue;
}
