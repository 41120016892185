function pluralize(count: number = 0, singularString: string, verb?: string) {
  let outString: string;
  if (count === 0) {
    outString = `no ${singularString}s`;
  } else if (count === 1) {
    outString = `${count.toLocaleString()} ${singularString}`;
  } else {
    outString = `${count.toLocaleString()} ${singularString}s`;
  }
  if (verb) {
    outString = `${getPrefix(count, verb)} ${outString}`;
  }
  return outString;
}

function getPrefix(count: number, prefixString: string) {
  switch (prefixString) {
    case 'is':
    case 'are':
      if (count === 1) return 'is';
      else return 'are';
    case 'has':
    case 'have':
      if (count === 1) return 'has';
      else return 'have';
    default:
      throw new Error('Invalid verb');
  }
}

export default pluralize;
