import React, { useContext } from 'react';
import { Transaction, WelcomeContext } from './Welcome';
import { Alert, Button, ButtonGroup, Stack } from 'react-bootstrap';
import LineItem from './LineItem';
import SumLine from './SumLine';
import Tip from '../Tip';
import { Timescale } from '../../shared/BudgetCalendar';
import DollarAmount from '../DollarAmount';
import NavButtons from './NavButtons';
import { Metric, trackMetric } from '../../utils/metrics';

export default function SafeToSpendStep() {
  const {
    allowanceMonthly,
    allowanceWeekly,
    discretionaryExpenses,
    setDiscretionaryExpenses,
    safeToSpend,
    discretionaryExpensesSum,
    timescale,
    setTimescale,
  } = useContext(WelcomeContext);

  function handleExpenseChange(expense: Transaction) {
    const index = discretionaryExpenses.findIndex(({ name }) => name === expense.name);
    const newDiscretionaryExpenses =
      index === -1 ? [...discretionaryExpenses, expense] : discretionaryExpenses.filter((_, i) => i !== index);

    setDiscretionaryExpenses(newDiscretionaryExpenses);
    trackMetric(Metric.WELCOME_DISCRETIONARY_EXPENSE_SELECTED, { expenseName: expense.name });
  }

  function handleTimescaleChange(timescale: Timescale) {
    setTimescale(timescale);
    trackMetric(Metric.WELCOME_TIMESCALE_CHANGED, { timescale });
  }

  const examples = [
    { name: 'Coffee', amount: 10, icon: '☕️' },
    { name: 'Groceries', amount: 200, icon: '🛒' },
    { name: 'Clothes shopping', amount: 100, icon: '🛍️' },
    { name: 'Transportation', amount: 20, icon: '🚕' },
    { name: 'Dining out', amount: 80, icon: '🍱' },
    { name: 'Movie', amount: 30, icon: '🍿' },
  ];

  const timescaleSafeToSpend = timescale === Timescale.Week ? allowanceWeekly + discretionaryExpensesSum : safeToSpend;

  return (
    <>
      <h1>Enjoy spending with confidence</h1>

      <p>
        As you spend, Lopery subtracts your spending from your allowance. This is your <dfn>”Safe to Spend,”</dfn> the only number you need
        to know if you’re on or over budget.
      </p>

      <p>At the start of each week (or month if you prefer), your Safe to Spend resets based on your allowance.</p>

      <Tip>
        As long as your Safe to Spend is positive, you will be able to cover all your recurring expenses and any new credit card charges.
      </Tip>

      <hr />

      <p>Select a few sample discretionary expenses to see Safe to Spend is affected:</p>

      <div className="text-center">
        <ButtonGroup size="sm" className="mx-auto">
          <Button
            variant={timescale === Timescale.Week ? 'primary' : 'outline-primary'}
            onClick={() => handleTimescaleChange(Timescale.Week)}
          >
            Weekly
          </Button>
          <Button
            variant={timescale === Timescale.Month ? 'primary' : 'outline-primary'}
            onClick={() => handleTimescaleChange(Timescale.Month)}
          >
            Monthly
          </Button>
        </ButtonGroup>
      </div>

      <Stack className="my-3" gap={2}>
        <LineItem
          transaction={{ icon: '💌', name: 'Starting allowance', amount: allowanceMonthly }}
          amountElement={<DollarAmount amount={timescale === Timescale.Week ? allowanceWeekly : allowanceMonthly} per={timescale} />}
        />

        <div>
          {examples.map(({ name, amount, icon }) => (
            <LineItem
              key={name}
              transaction={{ icon, name, amount: amount }}
              onSelect={handleExpenseChange}
              selected={discretionaryExpenses.findIndex((expense) => expense.name === name) >= 0}
            />
          ))}
        </div>

        <SumLine />

        <LineItem
          transaction={{ icon: '😇', name: 'Safe to Spend', amount: safeToSpend }}
          animated
          amountElement={<DollarAmount amount={timescaleSafeToSpend} per={timescale} color />}
        />
      </Stack>

      <Tip>Your actual spending will be imported in real-time when you securely connect your spending accounts.</Tip>

      {timescaleSafeToSpend >= 0 && (
        <Alert variant="danger" className="mt-3">
          To continue, please adjust the numbers so that your Safe to Spend is positive.
        </Alert>
      )}

      <NavButtons isNextDisabled={discretionaryExpenses.length === 0 || timescaleSafeToSpend >= 0} />
    </>
  );
}
