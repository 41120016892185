import React, { useState } from 'react';
import GoalModalForm from './GoalModalForm';
import { Button, ButtonProps } from 'react-bootstrap';

interface NewGoalButtonProps extends ButtonProps {
  children?: React.ReactNode;
}

export function NewGoalButton({ children = <>Create a goal</>, ...rest }: NewGoalButtonProps) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        onClick={() => {
          setShowModal(true);
        }}
        {...rest}
      >
        {children}
      </Button>

      <GoalModalForm showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}
