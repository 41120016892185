import React from 'react';
import pluralize from '../../shared/pluralize';
import DollarAmount from '../DollarAmount';
import { useDashboard } from '../../contexts/DashboardContext';
import BetterAccordionItem from '../BetterAccordionItem';
import ListSection, { ListSectionItem } from './ListSection';
import { Link } from 'react-router-dom';
import { generateRoute, Route } from '../../Routes';

export default function RunwayAccordionItem() {
  const { runway, timescale, budgetPeriods, setShowBudgetModal } = useDashboard();

  return (
    <BetterAccordionItem
      key="allowance"
      eventKey="allowance"
      header="Allowance"
      secondLine={`Based on your average spending over the last ${pluralize(runway.previousUtilizations.length, timescale)}`}
      secondColumn={<DollarAmount amount={runway.averageUtilization} keepSign color />}
    >
      <ListSection>
        {runway.previousUtilizations.map((utilization, i) => (
          <ListSectionItem
            name={
              <Link
                to={generateRoute(Route.Budget, {
                  timescale,
                  date: budgetPeriods.utilization.start.subtract(i + 1, timescale).format('YYYY-MM-DD'),
                })}
                onClick={() => setShowBudgetModal(false)}
              >
                {i === 0 ? `Last ${timescale}` : `${pluralize(i + 1, timescale)} ago`}
              </Link>
            }
            value={<DollarAmount amount={utilization} color />}
          />
        ))}
      </ListSection>
    </BetterAccordionItem>
  );
}
