import React from 'react';
import { sortBy } from 'underscore';
import { Timescale } from '../../shared/BudgetCalendar';
import BetterAccordionItem from '../BetterAccordionItem';
import DollarAmount from '../DollarAmount';
import ListSection, { ListSectionItem } from './ListSection';
import { Link } from 'react-router-dom';
import { generateRoute, Route } from '../../Routes';
import sumAmounts from '../../utils/sumAmounts';
import { useDashboard } from '../../contexts/DashboardContext';
import useTransactions from '../../hooks/useTransactions';
import { TransactionRowMetadata } from '../TransactionRow';
import isTransactionRecurring from '../../shared/isTransactionRecurring';

export default function NetIncomeAccordionItem() {
  const { budget, timescale, budgetPeriods, accountsById, includedAccounts } = useDashboard();

  const { error, isLoading, transactions } = useTransactions({
    startDate: budgetPeriods.recurrence.start,
    endDate: budgetPeriods.recurrence.end,
    accounts: includedAccounts,
    orderByDirection: 'desc',
  });

  const recurringTransactions = transactions.filter(isTransactionRecurring);

  const netIncome =
    timescale === Timescale.Week
      ? (budget.weekRecurrencePeriodIncomesSum + budget.weekRecurrencePeriodExpensesSum) / (budget.weekRecurrencePeriodLength / 7)
      : budget.monthRecurrencePeriodIncomesSum + budget.monthRecurrencePeriodExpensesSum;

  return (
    <BetterAccordionItem
      key="allowance"
      eventKey="allowance"
      header="Allowance"
      secondLine={`Based on net recurring income & expenses for the prior month (${budgetPeriods.recurrence.start.format(
        'MMM D',
      )} - ${budgetPeriods.recurrence.end.format('MMM D')})${timescale === Timescale.Week ? ' broken down by week' : ''}`}
      secondColumn={<DollarAmount amount={netIncome} color />}
      isLoading={isLoading}
      error={error}
    >
      <ListSection isLoading={isLoading}>
        {sortBy(recurringTransactions, 'amount').map((transaction) => (
          <ListSectionItem
            key={transaction.transactionId}
            name={
              <Link to={generateRoute(Route.Transaction, { transactionId: transaction.transactionId })} style={{ color: 'inherit' }}>
                {transaction.name}
              </Link>
            }
            value={
              <DollarAmount
                amount={timescale === Timescale.Week ? transaction.amount / (budget.weekRecurrencePeriodLength / 7) : transaction.amount}
                per={timescale}
                color
              />
            }
            detail={<TransactionRowMetadata transaction={transaction} account={accountsById[transaction.accountId]} />}
            detailValue={timescale === Timescale.Week && <DollarAmount amount={transaction.amount} per={Timescale.Month} />}
          />
        ))}
      </ListSection>
    </BetterAccordionItem>
  );
}
