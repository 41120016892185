import React, { useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import NavButtons from './NavButtons';
import { WelcomeContext } from './Welcome';
import Tip from '../Tip';
import { Metric, trackMetric } from '../../utils/metrics';

const MONTHLY_INCOME_OPTIONS = [
  { label: 'Less than $2.5k', value: 2000 },
  { label: 'Between $2.5k and $5k', value: 3500 },
  { label: 'Between $5k and $10k', value: 7500 },
  { label: 'More than $10k', value: 12500 },
];

export default function IncomeStep() {
  const { monthlyIncome, setMonthlyIncome } = useContext(WelcomeContext);

  const [positiveIncome, setPositiveIncome] = useState(monthlyIncome * -1);

  function handleMonthlyIncomeChange(event) {
    const newMonthlyIncome = parseFloat(event.target.value);
    setPositiveIncome(newMonthlyIncome);
    setMonthlyIncome(newMonthlyIncome * -1);
    trackMetric(Metric.WELCOME_INCOME_SELECTED, { monthlyIncome: newMonthlyIncome });
  }

  return (
    <>
      <h1>Every month, I make&hellip;</h1>

      <p>What’s your approximate monthly take-home income, after taxes & benefits?</p>

      {MONTHLY_INCOME_OPTIONS.map((option) => (
        <label className="d-block p-2">
          <Form.Check
            type="radio"
            name="monthlyIncome"
            id={`monthly-income-${option.value}`}
            value={option.value}
            checked={positiveIncome === option.value}
            onChange={handleMonthlyIncomeChange}
            inline
          />
          {option.label}
        </label>
      ))}

      <div className="mt-3">
        <Tip>Later, when you securely connect your accounts, this information will be imported automatically.</Tip>
      </div>

      <NavButtons isNextDisabled={monthlyIncome >= 0} />
    </>
  );
}
