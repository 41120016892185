import { Query, QuerySnapshot, onSnapshot as firestoreOnSnapshot, FirestoreError, Unsubscribe } from 'firebase/firestore';
import { useEffect, useState } from 'react';

interface Options {
  /** An optional callback that's called everytime a snapshot is received. */
  onSnapshot?: (callback: QuerySnapshot) => void;
  /** An optional callback that's called if one of Firestore's `onSnapshot`'s errors. */
  onError?: (error: FirestoreError) => void;
  /** A key to identify the query, such that if the key changes, Firestore's existing `onSnapshot`s will be unsubscribed and recreated with the new query. */
  queryKey?: string;
}

export default function useOnSnapshot<BaseDocData>(query: Query, { onSnapshot, onError, queryKey }: Options = {}) {
  const [docs, setDocs] = useState<BaseDocData[]>([]);
  const [unsubscribes, setUnsubcribes] = useState<Unsubscribe[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  function subscribe() {
    setIsLoading(true);

    const unsubscribe = firestoreOnSnapshot(
      query,
      (snapshot) => {
        setDocs(snapshot.docs.map((doc) => ({ __id: doc.id, ...doc.data() } as BaseDocData)));
        if (onSnapshot) onSnapshot(snapshot);
        setIsLoading(false);
      },
      (error) => {
        onError && onError(error);
        setIsLoading(false);
      },
    );

    setUnsubcribes((prev) => [...prev, unsubscribe]);
  }

  function unsubscribe() {
    unsubscribes.forEach((fn) => fn());
  }

  useEffect(() => {
    unsubscribe();
    subscribe();
    return unsubscribe;
  }, [queryKey]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    docs,
    isLoading,
    subscribe,
    unsubscribe,
  };
}
