import React from 'react';
import { Placeholder as BootstrapPlaceholder } from 'react-bootstrap';

function Placeholder() {
  return (
    <>
      <BootstrapPlaceholder animation="wave">
        <BootstrapPlaceholder xs={8} size="xs" />
        <BootstrapPlaceholder xs={{ span: 2, offset: 2 }} size="xs" className="me-auto" />
      </BootstrapPlaceholder>
      <BootstrapPlaceholder animation="wave">
        <BootstrapPlaceholder xs={6} size="xs" />
      </BootstrapPlaceholder>
    </>
  );
}

interface ListSectionProps {
  isLoading?: boolean;
  children?: React.ReactNode;
}

export default function ListSection({ isLoading, children }: ListSectionProps) {
  return isLoading ? <Placeholder /> : <>{children}</>;
}

interface ListSectionItemProps {
  name: React.ReactNode;
  value: React.ReactNode;
  detail?: React.ReactNode;
  detailValue?: React.ReactNode;
}

export function ListSectionItem({ name, value, detail, detailValue }: ListSectionItemProps) {
  return (
    <div className="small-font list-item-with-border" style={{ padding: '8px 0' }}>
      <div style={{ float: 'right' }} className="text-end ms-3">
        <div>{value}</div>
        {detailValue && <div className="text-muted">{detailValue}</div>}
      </div>
      <div style={{ fontWeight: 500 }}>
        <strong>{name}</strong>
      </div>
      {detail && (
        <div className="text-muted" style={{ paddingTop: 4, display: 'flex' }}>
          <div style={{ flex: 1 }}>{detail}</div>
        </div>
      )}
    </div>
  );
}
