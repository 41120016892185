import React from 'react';
import { createPortal } from 'react-dom';
import { BarLoader } from 'react-spinners';
import { useAuth } from '../App';

const HEIGHT = 2;

export default function Loader({ isLoading }) {
  const { userData } = useAuth();

  return createPortal(
    <div style={{ height: HEIGHT }}>
      {(userData?.isRecalculatingAllBudgets || isLoading) && <BarLoader height={HEIGHT} color="var(--bs-blue)" width="100%" />}
    </div>,
    document.getElementById('loader'),
  );
}
