import React from 'react';
import { useEffect, useState } from 'react';
import { Alert, Modal, Spinner, Table } from 'react-bootstrap';
import { doc, getDoc } from 'firebase/firestore';
import db from '../../firebase';
import { useAuth } from '../../App';
import DollarAmount from '../DollarAmount';
import { utcDay } from '../../shared/days';
import { useInstallments } from '../../hooks/useInstallments';
import dayjs from 'dayjs';
import DeletePlanButton from './DeletePlanButton';
import { ComponentsSeparatedByDots } from '../DotSeparator';
import pluralize from '../../shared/pluralize';
import { Link, useNavigate } from 'react-router-dom';
import { Route, generateRoute } from '../../Routes';
import { PlanDocData } from '../../types';

interface PlanModalProps {
  plan: PlanDocData;
  show: boolean;
}

export default function PlanModal({ plan, show }: PlanModalProps) {
  const { uid } = useAuth();
  const navigate = useNavigate();

  const [isTransactionLoading, setIsTransactionLoading] = useState(true);
  const [transaction, setTransaction] = useState(null);

  const {
    installments,
    isLoading: isInstallmentsLoading,
    error: installmentsError,
  } = useInstallments({
    planId: plan.__id,
  });

  useEffect(() => {
    if (!show || !plan?.transactionId) return;

    const getTransaction = async () => {
      const transactionDoc = doc(db, 'users', uid, 'transactions', plan.transactionId);
      const transaction = (await getDoc(transactionDoc)).data();
      setTransaction(transaction);
      setIsTransactionLoading(false);
    };

    getTransaction();
  }, [plan, uid, show]);

  const isDisbursement = plan.targetAmount < 0;
  const remaining = plan.targetAmount - plan.installmentsSum;
  const weeksRemaining = Math.ceil(remaining / plan.installmentAmount);
  const isAmortization = !!plan.transactionId;

  return (
    <Modal show={show} onHide={() => navigate(plan.transactionId ? Route.Recoveries : Route.Goals)}>
      {plan && (
        <>
          <Modal.Header closeButton>
            <Modal.Title>{plan.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="danger" show={!!installmentsError}>
              {installmentsError?.message}
            </Alert>

            {isAmortization ? (
              <p>
                On {dayjs(plan.createdAt.toDate()).format('MMMM D, YYYY')}, you recovered a{' '}
                <DollarAmount amount={plan.targetAmount} absolute /> {isDisbursement ? 'deposit' : 'expense'} into weekly installments of{' '}
                <DollarAmount amount={plan.installmentAmount} absolute />, which are {isDisbursement ? 'added' : 'subtracted'} from your
                allowance.
              </p>
            ) : (
              <p>
                On {dayjs(plan.createdAt.toDate()).format('MMMM D, YYYY')}, you created a goal to{' '}
                {plan.isPerpetual ? (
                  <>
                    save <DollarAmount amount={plan.installmentAmount} absolute /> each week.
                  </>
                ) : (
                  <>
                    save <DollarAmount amount={plan.targetAmount} absolute /> by curtailing your weekly spending limit by{' '}
                    <DollarAmount amount={plan.installmentAmount} absolute />.
                  </>
                )}
              </p>
            )}

            <Table className="mb-0" borderless>
              <tbody>
                {plan.transactionId && (
                  <tr>
                    <th>Transaction</th>
                    <td>
                      {isTransactionLoading ? (
                        <Spinner size="sm" />
                      ) : (
                        <>
                          {transaction ? (
                            <>
                              <div>
                                <Link to={generateRoute(Route.Transaction, { transactionId: transaction.transactionId })}>
                                  {transaction.name}
                                </Link>
                              </div>
                              <div className="small-font text-muted">
                                <ComponentsSeparatedByDots
                                  components={[
                                    <DollarAmount amount={transaction.amount} color />,
                                    <>{utcDay(transaction.date.toDate()).format('MMMM D, YYYY')}</>,
                                  ]}
                                />
                              </div>
                            </>
                          ) : (
                            <div className="text-danger">Not found</div>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                )}
                <tr>
                  <th>Installment amount</th>
                  <td>
                    <div>
                      <DollarAmount amount={Math.abs(plan.installmentAmount)} absolute />{' '}
                      <div className="small-font text-muted">
                        {isDisbursement ? 'Added to' : 'Deducted from'} your spending limit each week
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>{isAmortization ? 'Recovered' : 'Saved'}</th>
                  <td>
                    <DollarAmount amount={plan.installmentsSum} absolute />
                    {!plan.isPerpetual && (
                      <>
                        {' '}
                        of <DollarAmount amount={plan.targetAmount} absolute />
                        <div className="small-font text-muted">
                          <DollarAmount amount={remaining} absolute /> remaining ({pluralize(weeksRemaining, 'more week')})
                        </div>
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Installments</th>
                  <td>
                    {isInstallmentsLoading ? (
                      <Spinner size="sm" />
                    ) : (
                      <>
                        {installments.length > 0 ? (
                          <ol className="small-font text-muted mb-0 mt-1 ">
                            {installments.map((installment) => (
                              <li key={installment.__id}>
                                <div className="d-flex justify-content-between">
                                  <span>Week of {utcDay(installment.date.toDate()).format('MMMM D')}</span>
                                  <DollarAmount amount={installment.amount} absolute />
                                </div>
                              </li>
                            ))}
                          </ol>
                        ) : (
                          <>
                            None<div className="small-font text-muted">Will start next week</div>
                          </>
                        )}
                      </>
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <DeletePlanButton plan={plan} />
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}
