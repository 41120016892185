import formatCurrency from './formatCurrency';

type SafeToSpend = {
  /** The amount (always positive because we use the color and label to provide context). */
  amount: number;
  /** The amount formatted as a dollar amount. */
  amountFormatted: string;
  /** The Bootstrap color name to use when rendering the amount. */
  color: 'success' | 'danger';
  /** The label to use when rendering the amount. */
  label: 'Safe to Spend' | 'overspent';
};

/**
 * A class that makes rendering data from a Budget a bit simpler.
 * Historically, this used to be done on the Budget via a method
 * called buildSummaries. But that eventually fell over because
 * in order to make view changes, a full budget recalculation had
 * to be performed. In the future, this class could take in a
 * shared type (e.g., BudgetDocData or BudgetData).
 */
class BudgetView {
  /**
   * Maps directly to a Budget’s `weekAllowance` or `monthAllowance`. Remember,
   * a negative number means there is money to spend.
   */
  allowance: number;
  /**
   * Maps directly to a Budget’s `weekUtilization` or `monthUtilization`. Remember,
   * a positive number means money has been spent.
   */
  utilization: number;

  /**
   * @param allowance Allowance for the respective week or month
   * @param averageUtilization Average utilization for the respective week or month
   * @param utilization Week or month utilization
   * @param isAllowanceBasedOnMovingAverage A flag to determine whether to flip the sign of average utilization (to treat it as "income")
   */
  constructor(allowance: number, averageUtilization: number, utilization: number, isAllowanceBasedOnMovingAverage: boolean) {
    this.allowance = isAllowanceBasedOnMovingAverage ? averageUtilization * -1 : allowance;
    this.utilization = utilization;
  }

  /**
   * Allowance minus utilization. A negative number means there is still
   * money to spend, while a positive number indicates an overage. This
   * can also be referred to as “Safe to Spend”.
   */
  get remainder() {
    return this.allowance + this.utilization;
  }

  /**
   * Returns true if the remainder is greater than 0.
   */
  get isOver() {
    return this.remainder > 0;
  }

  /**
   * An email preheader is the text that appears in the inbox after the subject line.
   * Without it, the client would just make a best guess based on the email content.
   * Note that the context in which this string is being rendered assumes week timescale.
   */
  get emailPreheader() {
    const spent = formatCurrency(this.utilization);
    const allowance = formatCurrency(this.allowance * -1);
    const overage = formatCurrency(this.remainder);
    const remainder = formatCurrency(this.remainder * -1);

    if (this.isOver) return `You’ve spent ${spent} of your ${allowance} weekly spending limit and are over by ${overage} for the week.`;

    return `You’ve spent ${spent} of your ${allowance} weekly spending limit and have ${remainder} left to spend this week.`;
  }

  /**
   * The subject line of the email. This is a bit more concise than the preheader.
   */
  get emailSubject() {
    const overage = formatCurrency(this.remainder);
    const remainder = formatCurrency(this.remainder * -1);

    if (this.isOver) return `${overage} overspent this week`;

    return `${remainder} left to spend this week`;
  }

  /**
   * An object that contains the amount, color, and label for the “Safe to Spend” amount.
   */
  get safeToSpend(): SafeToSpend {
    const amount = Math.abs(this.remainder);

    return {
      amount,
      amountFormatted: formatCurrency(this.remainder * -1),
      color: this.isOver ? 'danger' : 'success',
      label: 'Safe to Spend',
    };
  }
}

export default BudgetView;
