import React from 'react';
import NavButtons from './NavButtons';

export default function IntroStep() {
  return (
    <>
      <h1>Welcome!</h1>

      <p>Money is about to get a whole lot easier.</p>

      <p>
        In three short steps, we’ll explain the basics of how Lopery works with some <strong>sample data</strong>.
      </p>

      <p>You’ll learn about:</p>

      <ol>
        <li>Income & recurring expenses</li>
        <li>Allowance</li>
        <li>Safe to Spend</li>
      </ol>

      <p>
        Then you’ll sign up, securely connect your bank accounts, and Lopery will automatically configure your budget based on real data.
      </p>

      <NavButtons isNextDisabled={false} />
    </>
  );
}
