import React, { useState } from 'react';
import usePlans from '../../hooks/usePlans';
import { Col, Form, Row, Stack } from 'react-bootstrap';
import { sortBy } from 'underscore';
import Plan from './Plan';
import PlanModal from './PlanModal';
import { PlanDocData } from '../../types';

interface PlansProps {
  plans: PlanDocData[];
  planId?: string;
}

enum Filter {
  Active = 'active',
  Completed = 'completed',
}

export default function Recoveries({ plans, planId }: PlansProps) {
  const [filter, setFilter] = useState<string>(Filter.Active);

  const active = plans.filter((plan) => plan.installmentsSum !== plan.targetAmount);
  const completed = plans.filter((plan) => plan.installmentsSum === plan.targetAmount);
  const filteredPlans = filter === Filter.Active ? active : completed;
  const plan = plans.find((plan) => plan.__id === planId);

  return (
    <Stack gap={3}>
      <Row>
        <Col xs={6}>
          <h3>Recoveries</h3>
          {plans.length > 0 && (
            <Form.Select onChange={(event) => setFilter(event.target.value)} value={filter}>
              <option value={Filter.Active}>Active ({active.length})</option>
              <option value={Filter.Completed}>Completed ({completed.length})</option>
            </Form.Select>
          )}
        </Col>
      </Row>

      {filteredPlans.length === 0 && <p className="mb-0 text-muted">No {filter} recoveries.</p>}

      <Stack gap={2}>
        {sortBy(filteredPlans, (plan) => (plan.targetAmount - plan.installmentsSum) / plan.targetAmount).map((plan: PlanDocData, i) => (
          <Plan plan={plan} key={plan.__id} />
        ))}
      </Stack>

      {plan && <PlanModal plan={plan} show={true} />}
    </Stack>
  );
}

export { usePlans };
