import React, { FormEvent, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';
import { BsClipboardData } from 'react-icons/bs';
import BudgetCalendar, { Timescale } from '../shared/BudgetCalendar';
import pluralize from '../shared/pluralize';
import DollarAmount from './DollarAmount';
import Tip from './Tip';
import { Timestamp } from 'firebase/firestore';
import { useAuth } from '../App';
import ErrorAlert from './ErrorAlert';
import roundCurrency from '../shared/roundCurrency';
import { createPlan } from '../utils/plan';
import { utcDay } from '../shared/days';
import BetterButton from './BetterButton';
import { PlanDocData } from '../types';

const NUM_WEEKS_DEFAULT = 8;

function Slider({ numWeeks, onChange }: { numWeeks: number; onChange: (numWeeks: number) => void }) {
  return (
    <div style={{ display: 'flex', gap: 16 }}>
      <div style={{ flex: 1 }}>
        <Form.Range name="numWeeks" defaultValue={numWeeks} onChange={(event) => onChange(parseInt(event.target.value))} min={2} max={52} />
      </div>
      <div>{pluralize(numWeeks, 'week')}</div>
    </div>
  );
}

export default function RecoverModalForm({ show, onHide, onSuccess, transaction: { transactionId, name, amount, pending, date } }) {
  const { uid } = useAuth();
  const [numWeeks, setNumWeeks] = useState(NUM_WEEKS_DEFAULT);
  const [isLoading, setIsLoading] = useState(false);
  const [planName, setPlanName] = useState(name);
  const [error, setError] = useState();

  const {
    utilization: { start },
  } = BudgetCalendar.getBudgetPeriodsByDate(Timescale.Week, utcDay(date.toDate()));
  const end = start.add(numWeeks - 1, 'weeks');

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setError(null);
    setIsLoading(true);

    try {
      const planData: PlanDocData = {
        createdAt: Timestamp.now(),
        installmentAmount: weeklyAmount,
        installmentsSum: 0,
        isPerpetual: false,
        name: planName,
        start: Timestamp.fromDate(start.toDate()),
        targetAmount: amount,
        transactionId,
        updatedAt: Timestamp.now(),
      };

      await createPlan(uid, planData);

      onSuccess(planData);
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setIsLoading(false);
      setNumWeeks(NUM_WEEKS_DEFAULT);
    }
  }

  const weeklyAmount = roundCurrency(amount / numWeeks);

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Recover</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ErrorAlert error={error} />

          <p>
            Spread out the amount of this transaction over time. Instead of it impacting a single week, recovering the amount reduces and
            distributes the impact evenly over many weeks.
          </p>
          {pending && (
            <Alert>
              This transaction is pending. When it settles, the amount may change and the recovered amount and installments will
              automatically update.
            </Alert>
          )}

          <Form.Group className="mb-3">
            <Form.Label>
              <strong>Name</strong>
            </Form.Label>
            <Form.Control type="text" name="name" value={planName} onChange={(event) => setPlanName(event.target.value)} />
            <Form.Text>Feel free to rename this to something more memorable.</Form.Text>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              <strong>Number of weeks</strong>
            </Form.Label>
            <Slider numWeeks={numWeeks} onChange={setNumWeeks} />
          </Form.Group>

          <Form.Group>
            <Tip icon={<BsClipboardData />}>
              <strong>
                <DollarAmount amount={weeklyAmount} absolute />
              </strong>{' '}
              will be {weeklyAmount < 0 ? 'added to' : 'deducted from'} your allowance this week and every following week until{' '}
              <strong>{end.format('dddd, MMMM D, YYYY')}</strong>.
            </Tip>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <BetterButton isLoading={isLoading} type="submit">
            Recover
          </BetterButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export { Slider };
