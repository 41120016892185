import React from 'react';
import { BsInfoCircle } from 'react-icons/bs';
import { useAppLayout } from '../layouts/AppLayout';

interface HelpfulLabelProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  explainerModalId?: string;
  iconColor?: string;
  onClick?: () => void;
}

export default function HelpfulLabel({ children, style, explainerModalId, iconColor = 'var(--bs-secondary)', onClick }: HelpfulLabelProps) {
  const { openExplainerModal } = useAppLayout();

  return (
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        gap: 4,
        cursor: 'pointer',
        padding: 2,
        whiteSpace: 'nowrap',
        ...style,
      }}
      onClick={() => (onClick ? onClick() : openExplainerModal(explainerModalId))}
    >
      {children}
      <BsInfoCircle color={iconColor} />
    </div>
  );
}
