import React, { forwardRef, useCallback, useState } from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import { BsArrowRepeat, BsChevronDown, BsClipboardData, BsEyeSlash, BsInfoCircle } from 'react-icons/bs';
import { useAuth } from '../App';
import { isTransactionExpense } from '../shared/isTransactionExpense';
import { ExplainerModalId, useAppLayout } from '../layouts/AppLayout';
import DollarAmount from './DollarAmount';
import toggleTransactionField from '../utils/toggleTransactionField';
import RecoverModalForm from './RecoverModalForm';
import formatCurrency from '../shared/formatCurrency';
import pluralize from '../shared/pluralize';
import { recalculateBudgets } from '../firebase';
import { removePlan } from '../utils/plan';
import { PlanDocData, TransactionDocData } from '../types';
import BetterButton from './BetterButton';

interface CustomToggleProps {
  children: React.ReactNode;
  afterIcon: React.ReactNode;
  onClick: (event: React.MouseEvent) => void;
}

const CustomToggle = forwardRef(({ children, afterIcon, onClick }: CustomToggleProps, ref) => {
  const { isDarkMode } = useAppLayout();

  function handleClick(event: React.MouseEvent) {
    event.preventDefault();
    onClick(event);
  }

  return (
    <BetterButton size="sm" variant={isDarkMode ? 'dark' : 'light'} ref={ref} onClick={handleClick} afterIcon={afterIcon}>
      {children}
    </BetterButton>
  );
});

const CustomItem = ({ beforeIcon, onExplain, children, ...props }) => (
  <div {...props} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', gap: 8 }}>
    {beforeIcon}
    <div style={{ flex: 1 }}>{children}</div>
    <BsInfoCircle
      color={`var(${props['aria-selected'] ? '--bs-dropdown-link-active-color' : '--bs-secondary'}`}
      onClick={(event) => {
        event.stopPropagation();
        onExplain();
      }}
    />
  </div>
);

const TransactionAmountButton = ({ transaction }: { transaction: TransactionDocData }) => {
  const { uid } = useAuth();
  const { openExplainerModal } = useAppLayout();
  const [showRecoverModalForm, setShowRecoverModalForm] = useState(false);
  const { setToast } = useAppLayout();
  const [isLoading, setIsLoading] = useState(false);

  const { isRecurring, isIgnored, amount, planId, transactionId, name, date } = transaction;

  const handleSelect = useCallback(
    async (eventKey: string) => {
      if (eventKey === 'recover')
        if (planId) {
          setIsLoading(true);

          try {
            await removePlan(uid, { __id: planId, transactionId, start: date } as PlanDocData);
            await recalculateBudgets(date.toDate());
          } catch (error) {
            console.error(error);
          }

          setToast(`${name} is no longer recovered and affects this week’s Safe to Spend.`);

          setIsLoading(false);
        } else {
          setShowRecoverModalForm(true);
        }
      else {
        setIsLoading(true);

        await toggleTransactionField(uid, transaction, eventKey);

        if (eventKey === 'isRecurring')
          if (isRecurring) setToast(`${name} is no longer marked as recurring and affects your Safe to Spend.`);
          else setToast(`${name} is now marked as recurring, and will be factored into your future allowance.`);
        if (eventKey === 'isIgnored')
          if (isIgnored) setToast(`${name} is no longer ignored and affects your Safe to Spend.`);
          else setToast(`${name} is now ignored and no longer affects your Safe to Spend.`);

        setIsLoading(false);
      }
    },
    [uid, transactionId, date, name, isRecurring, isIgnored, planId, setToast, transaction],
  );

  async function handleAmortizationModalFormSuccess({ targetAmount, installmentAmount }: PlanDocData) {
    setIsLoading(false);
    setShowRecoverModalForm(false);
    setToast(
      `Your weekly allowance will be adjusted by ${formatCurrency(installmentAmount)} for ${pluralize(
        Math.ceil(targetAmount / installmentAmount),
        'week',
      )}.`,
    );
  }

  return (
    <>
      {/* Stop propogation allows this button to be clickable without triggering the click of the parent TransactionRow */}
      <Dropdown align="end" onClick={(event) => event.stopPropagation()} onSelect={handleSelect}>
        <Dropdown.Toggle
          afterIcon={
            isLoading ? (
              <Spinner size="sm" />
            ) : (
              <>
                {isRecurring && <BsArrowRepeat size="1.2em" />}
                {isIgnored && <BsEyeSlash size="1.2em" />}
                {!!planId && <BsClipboardData size="1.2em" />}
                {!isRecurring && !isIgnored && !planId && <BsChevronDown size="1.2em" />}
              </>
            )
          }
          as={CustomToggle}
        >
          <DollarAmount amount={amount} color showDecimals />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            as={CustomItem}
            eventKey="isRecurring"
            active={isRecurring}
            beforeIcon={<BsArrowRepeat />}
            onExplain={() => {
              openExplainerModal(
                isTransactionExpense(transaction) ? ExplainerModalId.RecurringExpenses : ExplainerModalId.RecurringIncomes,
              );
            }}
          >
            Recurring {isTransactionExpense(transaction) ? 'expense' : 'income'}
          </Dropdown.Item>
          <Dropdown.Item
            as={CustomItem}
            eventKey="isIgnored"
            active={isIgnored}
            beforeIcon={<BsEyeSlash />}
            onExplain={() => openExplainerModal(ExplainerModalId.IgnoredTransactions)}
          >
            Ignore
          </Dropdown.Item>
          <Dropdown.Item
            as={CustomItem}
            eventKey="recover"
            active={!!planId}
            beforeIcon={<BsClipboardData />}
            onExplain={() => openExplainerModal(ExplainerModalId.Recover)}
          >
            Recover
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <RecoverModalForm
        show={showRecoverModalForm}
        onHide={() => setShowRecoverModalForm(false)}
        onSuccess={handleAmortizationModalFormSuccess}
        transaction={transaction}
      />
    </>
  );
};

export default TransactionAmountButton;
