import React, { useState } from 'react';
import { Col, Form, Modal, Row, Stack } from 'react-bootstrap';
import BetterButton from './BetterButton';
import { TransactionDocData } from '../types';
import { utcDay, utcDayWithSameTime } from '../shared/days';
import DollarAmount from './DollarAmount';
import { useAuth } from '../App';
import { updateTransaction } from '../utils/transaction';
import { useAppLayout } from '../layouts/AppLayout';
import ErrorAlert from './ErrorAlert';
import TransactionName from './TransactionName';

interface TransactionModalFormProps {
  onHide: () => void;
  transaction: TransactionDocData;
}

const DATE_FORMAT = 'YYYY-MM-DD';
const DATE_FORMAT_DISPLAY = 'MM/DD/YYYY';
const MIN_MAX_LIMIT_VALUE = 30;
const MIN_MAX_LIMIT_UNIT = 'days';

/**
 * Test cases:
 * - dateOriginal should be set with time if changed
 * - nameOriginal should be set if changed
 * - dateOriginal should be cleared if set back to original
 * - nameOriginal should be cleared if set back to original
 * - date picker should be constrained within 30 days of original date
 * - typing in date outside the 30 day constraint should render an error
 * - original name should display if set
 * - original date should display if set
 */
export default function TransactionModalForm({ onHide, transaction }: TransactionModalFormProps) {
  const { uid } = useAuth();
  const { setToast } = useAppLayout();

  const [name, setName] = useState(transaction.name);
  const [nameIsValid, setNameIsValid] = useState(true);
  const [date, setDate] = useState(utcDay(transaction.date.toDate()).format(DATE_FORMAT));
  const [dateIsValid, setDateIsValid] = useState(true); // Do we really need this? What are invalid dates?
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const dateOriginal = utcDay((transaction.dateOriginal || transaction.date).toDate());
  const nameOriginal = transaction.nameOriginal || transaction.name;
  const formHasChanged = name !== transaction.name || date !== utcDay(transaction.date.toDate()).format(DATE_FORMAT);
  const transactionHasChanged = transaction.nameOriginal || transaction.dateOriginal;

  const endOfWeek = utcDay().endOf('week');
  const minDate = dateOriginal.subtract(MIN_MAX_LIMIT_VALUE, MIN_MAX_LIMIT_UNIT).startOf('week');
  let maxDate = dateOriginal.add(MIN_MAX_LIMIT_VALUE, MIN_MAX_LIMIT_UNIT).endOf('week');
  if (maxDate.isAfter(endOfWeek)) maxDate = endOfWeek;

  async function handleUpdate() {
    setIsLoading(true);

    const newNameIsValid = name.length > 0;

    const utcDate = utcDayWithSameTime(date, transaction.date.toDate());
    const newDateIsValid = utcDate.isValid() && utcDate.isSameOrAfter(minDate) && utcDate.isSameOrBefore(maxDate);

    setNameIsValid(newNameIsValid);
    setDateIsValid(newDateIsValid);

    if (!newNameIsValid || !newDateIsValid) {
      setIsLoading(false);
      return;
    }

    try {
      await updateTransaction(uid, transaction, {
        name,
        date: utcDate,
      });
    } catch (error) {
      setError(error);
      return;
    } finally {
      setIsLoading(false);
    }

    onHide();
    setToast('Transaction updated.');
  }

  function handleNameChange(event) {
    setName(event.target.value);
    setNameIsValid(true);
  }

  function handleDateChange(event) {
    setDate(event.target.value);
    setDateIsValid(true);
  }

  async function handleRevert() {
    setIsLoading(true);

    try {
      await updateTransaction(uid, transaction, {
        name: nameOriginal,
        date: dateOriginal,
      });
    } catch (error) {
      setError(error);
      return;
    } finally {
      setIsLoading(false);
    }

    onHide();
    setToast('Transaction reverted.');
  }

  return (
    <Modal show={true} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit transaction</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ErrorAlert error={error} />

        <Form onSubmit={handleUpdate}>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column xs="3" htmlFor="plan-name">
              Name
            </Form.Label>
            <Col xs="9">
              <Form.Control
                required
                isInvalid={!nameIsValid}
                type="text"
                value={name}
                onChange={handleNameChange}
                autoComplete="off"
                id="transaction-name"
              />
              {transaction.nameOriginal && (
                <Form.Text>
                  Original: <TransactionName id={transaction.transactionId} name={transaction.nameOriginal} />
                </Form.Text>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column xs="3" htmlFor="plan-name">
              Date
            </Form.Label>
            <Col xs="9">
              <Form.Control
                required
                isInvalid={!dateIsValid}
                type="date"
                value={date}
                onChange={handleDateChange}
                autoComplete="off"
                id="transaction-date"
                min={minDate.format(DATE_FORMAT)}
                max={maxDate.format(DATE_FORMAT)}
              />

              <Form.Control.Feedback type="invalid">
                Must be between {minDate.format(DATE_FORMAT_DISPLAY)} and {maxDate.format(DATE_FORMAT_DISPLAY)}.
              </Form.Control.Feedback>

              {transaction.dateOriginal && <Form.Text>Original: {dateOriginal.format(DATE_FORMAT_DISPLAY)}</Form.Text>}
            </Col>
          </Form.Group>
        </Form>
        <Row className="mb-3">
          <Col xs={3}>Amount</Col>
          <Col xs={9}>
            <DollarAmount amount={transaction.amount} color />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={3}>Category</Col>
          <Col xs={9}>{transaction.category.join(' / ')}</Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <div>
          {transactionHasChanged && (
            <BetterButton variant="outline-danger" hideLoadingIndicators isLoading={isLoading} onClick={handleRevert}>
              Revert
            </BetterButton>
          )}
        </div>
        <div>
          <Stack gap={2} direction="horizontal">
            <BetterButton type="submit" disabled={!formHasChanged} isLoading={isLoading} onClick={handleUpdate}>
              Update
            </BetterButton>
          </Stack>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
