import React from 'react';
import { Card } from 'react-bootstrap';
import { useAppLayout } from '../../layouts/AppLayout';
import { NewGoalButton } from './NewGoalButton';

export default function NoGoalsExplainer() {
  const { isDarkMode } = useAppLayout();

  return (
    <Card bg={isDarkMode ? 'dark' : 'light'} body className="text-center py-4 mt-4">
      <p>You haven’t created any goals or recoveries yet.</p>

      <NewGoalButton />
    </Card>
  );
}
