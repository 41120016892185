import React from 'react';

export default function TextWithIcon({ icon, children, reverse = false }) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        flexDirection: reverse ? 'row-reverse' : 'row',
      }}
    >
      {icon}
      <div style={{ flex: 1 }}>{children}</div>
    </div>
  );
}
