import React from 'react';
import Impersonation from '../components/Impersonation';
import useDocumentTitle from '../hooks/useDocumentTitle';

function ImpersonationPage() {
  useDocumentTitle('Impersonation');

  return <Impersonation />;
}

export default ImpersonationPage;
