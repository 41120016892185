import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import useDocumentTitle from '../hooks/useDocumentTitle';
import useScopeStyles from '../hooks/useScopeStyles';

const mediaQuerylist = window.matchMedia
  ? window.matchMedia('(prefers-color-scheme: dark)')
  : { matches: false, addEventListener: () => {} };

function WelcomeLayout() {
  useDocumentTitle();
  useScopeStyles('welcome');

  const [isDarkMode, setIsDarkMode] = useState(mediaQuerylist.matches);

  useEffect(() => {
    const html = document.querySelector('html');
    html.setAttribute('data-bs-theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  useEffect(() => {
    mediaQuerylist.addEventListener('change', ({ matches }: MediaQueryListEvent) => setIsDarkMode(matches));
  }, []);

  return (
    <main>
      <Outlet />
    </main>
  );
}

export default WelcomeLayout;
