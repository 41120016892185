import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const LANDING_PAGE_VERSION_STORAGE_KEY = 'landingPageVersion';
export const UTM_SOURCE_STORAGE_KEY = 'utmSource';
export const UTM_SOURCE_SEARCH_PARAM_KEY = 'utm_source';

export const LandingPageVersion = Object.freeze({
  fire: 'fire',
});

export const UtmSource = Object.freeze({
  ChooseFiBayArea: 'choosefi-bay-area',
});

export const LANDING_PAGE_VERSION_BY_UTM_SOURCE = Object.freeze({
  [UtmSource.ChooseFiBayArea]: LandingPageVersion.fire,
});

export default function useLandingPageVersion() {
  const [utmSource, setUtmSource] = useState(sessionStorage.getItem(UTM_SOURCE_STORAGE_KEY));

  const [landingPageVersion, setLandingPageVersion] = useState(sessionStorage.getItem(LANDING_PAGE_VERSION_STORAGE_KEY));

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const utmSource = searchParams.get(UTM_SOURCE_SEARCH_PARAM_KEY);

    if (!utmSource) return;

    setUtmSource(utmSource);
    sessionStorage.setItem(UTM_SOURCE_STORAGE_KEY, utmSource);

    const landingPageVersion = LANDING_PAGE_VERSION_BY_UTM_SOURCE[utmSource];

    if (!landingPageVersion) return;

    setLandingPageVersion(landingPageVersion);
    sessionStorage.setItem(LANDING_PAGE_VERSION_STORAGE_KEY, landingPageVersion);
  }, [searchParams]);

  return { utmSource, landingPageVersion };
}
