import React from 'react';
import { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import GoogleButton from 'react-google-button';
import { Modal, Stack } from 'react-bootstrap';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { Metric, MetricByPagePath, trackMetric } from '../utils/metrics';
import { Route } from '../Routes';
import { useAuth } from '../App';
import MarComHeader from '../components/MarComHeader';
import MarComFooter from '../components/MarComFooter';
import useScopeStyles from '../hooks/useScopeStyles';
import useLandingPageVersion from '../hooks/useLandingPageVersion';
import AppleButton from '../components/AppleButton';

function MarComLayout() {
  useDocumentTitle();
  useScopeStyles('marcom');
  useLandingPageVersion();

  const { user, signInWithGoogle, signInWithApple } = useAuth();
  const { pathname } = useLocation();
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);

  useEffect(() => {
    const firstPathSegment = pathname.split('/').slice(0, 2).join('/');

    switch (firstPathSegment) {
      case Route.Root:
        trackMetric(Metric.HOME_PAGE_VISITED);
        break;
      default:
        const metric = MetricByPagePath[firstPathSegment];
        if (metric) trackMetric(metric);
    }
  }, [user, pathname]);

  function handleSignIn() {
    setIsSigningUp(false);
    setShowAuthModal(true);
  }

  function handleSignUp() {
    setIsSigningUp(true);
    setShowAuthModal(true);
  }

  async function handleGoogleButtonClick() {
    try {
      await signInWithGoogle();
    } catch (error) {
      console.log(error);
    }
  }

  async function handleAppleButtonClick() {
    try {
      await signInWithApple();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <MarComHeader handleSignIn={handleSignIn} />

      <main>
        <Outlet context={[handleSignUp]} />
      </main>

      <MarComFooter />

      <Modal show={showAuthModal} onHide={() => setShowAuthModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{isSigningUp ? 'Sign up' : 'Sign in'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack gap={4} style={{ alignItems: 'center' }}>
            <GoogleButton label={isSigningUp ? 'Sign up with Google' : 'Sign in with Google'} onClick={handleGoogleButtonClick} />
            <AppleButton label={isSigningUp ? 'Sign up with Apple' : 'Sign in with Apple'} onClick={handleAppleButtonClick} />
            <div className="small-font text-muted">
              By signing up, you are agreeing to our <Link to={Route.Privacy}>Privacy</Link> and <Link to={Route.Security}>Security</Link>{' '}
              policies as well as the use of cookies.
            </div>
          </Stack>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MarComLayout;
