import React from 'react';
import { Image, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function AboutUsPage() {
  return (
    <div className="wrapper">
      <section className="floating-card">
        <Stack gap={3}>
          <h1>About us</h1>
          <div style={{ display: 'flex', textAlign: 'center', gap: '5%' }}>
            <div>
              <div>
                <Image src="/marcom/morgan.png" style={{ borderRadius: '100%' }} width={100} />
              </div>
              <Link to="https://www.linkedin.com/in/morganengel/">Morgan Engel</Link>
              <div className="small-font text-muted">Co-founder, CEO</div>
            </div>
            <div>
              <div>
                <Image src="/marcom/avand.png" style={{ borderRadius: '100%' }} width={100} />
              </div>
              <Link to="https://www.linkedin.com/in/avand/">Avand Amiri</Link>
              <div className="small-font text-muted">Co-founder, CPO</div>
            </div>
          </div>
          <hr />
          <div>Personal budgeting is a personal passion and pain point for both of us.</div>
          <div>
            Morgan and Avand had both built elegant spreadsheet-based versions of their budgeting systems. Morgan’s system became so
            polished that, in 2017, he shared his templates with friends and family and got a handful of long-term converts. We were
            desperate for an app that could replace our manual work with something automated. We’ve looked and it doesn’t exist.
          </div>
          <hr />
          <div>
            While Avand was working at Airbnb, he was making enough money to incrementally save up for a house. But housing in the Bay Area
            was untouchable—even with a software engineer’s salary. He started doing analysis of the broader CA market and, rather than
            finding a primary residence, he searched for investment properties instead.
          </div>
          <div>
            By comparing average nightly rates of vacation rentals to housing prices, he discovered Mariposa County, at the foothills of the
            Sierras and the gateway to Yosemite (pop. 17,000). Average annual Yosemite visitation is north of 3m and demand for lodging is
            high. He applied for a FHA loan and purchased a property with the $10,000 he had been able to save as a result of diligent
            budgeting. The amount he had been saving each month now went towards covering the monthly mortgage payment.
          </div>
          <div>
            Over the next few years, Avand was able to offset his expenses by renting the property on Airbnb. Now, it’s cashflow positive,
            offsetting his initial investment. Avand likes to call this his “free house” and wants everyone to have this kind of
            opportunity, which is exactly why he’s working on Lopery.
          </div>
        </Stack>
      </section>
    </div>
  );
}
