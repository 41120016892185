import React, { useContext } from 'react';
import { Bs1CircleFill, Bs2CircleFill, Bs3CircleFill, BsCheckCircleFill } from 'react-icons/bs';
import { STEPS, WelcomeContext } from './Welcome';

function Step({ n, children }) {
  const { stepIndex } = useContext(WelcomeContext);

  let color = 'var(--bs-gray-300)';
  if (stepIndex - 1 === n && stepIndex !== 0) {
    color = 'var(--bs-primary)';
    if (stepIndex === STEPS.length - 1) color = 'var(--bs-success)';
  }

  return <div style={{ color }}>{children}</div>;
}

function Line() {
  return <div style={{ background: 'var(--bs-gray-300)', width: '100%', height: 2, borderRadius: 2, transform: 'translateY(1px)' }} />;
}

export default function Breadcrumbs() {
  return (
    <div className="d-flex align-items-center justify-content-between mt-3 mb-4" style={{ gap: 4 }}>
      <Step n={0}>
        <Bs1CircleFill />
      </Step>
      <Line />
      <Step n={1}>
        <Bs2CircleFill />
      </Step>
      <Line />
      <Step n={2}>
        <Bs3CircleFill />
      </Step>
      <Line />
      <Step n={3}>
        <BsCheckCircleFill />
      </Step>
    </div>
  );
}
