import React from 'react';
import GoogleButton from 'react-google-button';
import AppleButton from '../../components/AppleButton';
import { useAuth } from '../../App';
import { Stack } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Route } from '../../Routes';
import { SecuritySummary } from '../../pages/SecurityPage';
import NavButtons from './NavButtons';
import { Metric, trackMetric } from '../../utils/metrics';

export default function SignUpStep() {
  const { signInWithGoogle, signInWithApple, user } = useAuth();
  const navigate = useNavigate();

  async function handleGoogleButtonClick() {
    trackMetric(Metric.WELCOME_SIGN_UP_CLICKED, { provider: 'Google' });
    try {
      await signInWithGoogle();
      navigate(Route.Onboarding);
    } catch (error) {
      console.log(error);
      trackMetric(Metric.WELCOME_SIGN_UP_ERROR_CAUGHT, { provider: 'Google', errorMessage: error.message });
    }
  }

  async function handleAppleButtonClick() {
    trackMetric(Metric.WELCOME_SIGN_UP_CLICKED, { provider: 'Apple' });
    try {
      await signInWithApple();
      navigate(Route.Onboarding);
    } catch (error) {
      console.log(error);
      trackMetric(Metric.WELCOME_SIGN_UP_ERROR_CAUGHT, { provider: 'Apple', errorMessage: error.message });
    }
  }

  console.log({ user });

  return (
    <>
      <h1>Let’s get real</h1>
      <p>Want to know your actual Safe to Spend? Sign up for free to find out:</p>
      <Stack gap={4} className="align-items-center my-5">
        <GoogleButton label="Sign up with Google" onClick={handleGoogleButtonClick} />
        <AppleButton label="Sign up with Apple" onClick={handleAppleButtonClick} />
        <div className="text-center small-font text-muted">
          By signing up, you are agreeing to our <Link to={Route.Privacy}>Privacy</Link> and <Link to={Route.Security}>Security</Link>{' '}
          policies as well as the use of cookies.
        </div>
      </Stack>

      <div
        style={{
          padding: '2em',
          background: 'var(--bs-tertiary-bg)',
          borderRadius: 'var(--bs-border-radius)',
        }}
      >
        <SecuritySummary />
      </div>

      <NavButtons isNextDisabled={true} />
    </>
  );
}
