interface SortableTransaction {
  /** Unix timestamp (milliseconds); for both dayjs() and new Date() use valueOf(); for Firestore Timestamp use toMillis() */
  unixTimestamp: number;
  amount: number;
  name: string;
}

// Note orderByDirection should match type of OrderByDirection from firebase/firestore,
// however, it is not imported because this function is shared by the back-end and there’s
// a type mistmatch between the two Firebase libraries.
export default function sortTransactions(a: SortableTransaction, b: SortableTransaction, orderByDirection: 'asc' | 'desc' = 'desc') {
  if (orderByDirection === 'asc') {
    const dateDiff = a.unixTimestamp - b.unixTimestamp;
    if (dateDiff !== 0) return dateDiff;
    const amountDiff = a.amount - b.amount;
    if (amountDiff !== 0) return amountDiff;
    return a.name < b.name ? 1 : -1;
  }

  const dateDiff = b.unixTimestamp - a.unixTimestamp;
  if (dateDiff !== 0) return dateDiff;
  const amountDiff = b.amount - a.amount;
  if (amountDiff !== 0) return amountDiff;
  return b.name < a.name ? 1 : -1;
}

export { SortableTransaction };
