import React, { useContext } from 'react';
import BetterButton from '../BetterButton';
import { BsArrowLeftCircle, BsChevronRight } from 'react-icons/bs';
import { STEPS, WelcomeContext } from './Welcome';
import { useNavigate } from 'react-router-dom';
import { generateRoute, Route } from '../../Routes';

export default function NavButtons({ isNextDisabled }: { isNextDisabled: boolean }) {
  const { stepIndex } = useContext(WelcomeContext);
  const navigate = useNavigate();

  function handleNextClick() {
    navigate(generateRoute(Route.Welcome, { step: STEPS[stepIndex + 1].path }));
  }

  function handleBackClick() {
    navigate(generateRoute(Route.Welcome, { step: STEPS[stepIndex - 1].path }));
  }

  return (
    <div className="d-flex justify-content-between mt-3">
      <div>
        {stepIndex > 0 && (
          <BetterButton variant="link" onClick={handleBackClick}>
            <BsArrowLeftCircle />
          </BetterButton>
        )}
      </div>
      <div>
        {stepIndex < STEPS.length - 1 && (
          <BetterButton afterIcon={<BsChevronRight />} variant="primary" onClick={handleNextClick} disabled={isNextDisabled}>
            Next
          </BetterButton>
        )}
      </div>
    </div>
  );
}
