import React from 'react';
import DollarAmount from '../DollarAmount';
import { Transaction } from './Welcome';
import { Form } from 'react-bootstrap';

interface LineItemProps {
  transaction: Transaction;
  onSelect?: (transaction: Transaction) => void;
  animated?: boolean;
  amountElement?: React.ReactElement;
  selected?: boolean;
}

export default function LineItem({ transaction, onSelect, selected, amountElement }: LineItemProps) {
  const { icon, name, amount } = transaction;

  function handleSelect() {
    onSelect(transaction);
  }

  return (
    <label className="d-block p-2">
      <div style={{ display: 'flex', gap: 8, margin: '2px 0', alignItems: 'center' }}>
        {onSelect && <Form.Check type="checkbox" onChange={handleSelect} checked={selected} />}
        <div>{icon}</div>
        <div style={{ flex: 2 }}>{name}</div>
        <div style={{ opacity: onSelect && !selected ? 0.0 : 1, transition: 'all 300ms' }}>
          {amountElement ? amountElement : <DollarAmount amount={amount} color />}
        </div>
      </div>
    </label>
  );
}
