import React from 'react';
import useDocumentTitle from '../hooks/useDocumentTitle';
import usePlans from '../hooks/usePlans';
import { Link, Navigate, useParams } from 'react-router-dom';
import { Alert, Nav, Stack } from 'react-bootstrap';
import BigSpinner from '../components/BigSpinner';
import NoGoalsExplainer from '../components/Plans/NoGoalsExplainer';
import ErrorAlert from '../components/ErrorAlert';
import { Route } from '../Routes';
import { useAuth } from '../App';
import Recoveries from '../components/Plans/Recoveries';
import Goals from '../components/Plans/Goals';

enum Tab {
  Goals = 'Goals',
  Recoveries = 'Recoveries',
}

type PlansPageProps = {
  tab: Tab;
};

function PlansPage({ tab }: PlansPageProps) {
  useDocumentTitle(tab.toString());

  const {
    userData: { isAllowanceBasedOnMovingAverage },
  } = useAuth();

  const { planId } = useParams<{ planId: string }>();

  const { plans: allPlans, goals, recoveries, error, isLoading } = usePlans();
  const plans = tab === Tab.Goals ? goals : recoveries;

  if (isLoading) return <BigSpinner />;

  if (allPlans.length === 0) {
    if (tab === Tab.Recoveries) return <Navigate to={Route.Goals} />;
    return <NoGoalsExplainer />;
  }

  return (
    <Stack gap={3}>
      {isAllowanceBasedOnMovingAverage && (
        <Alert className="mb-0">
          Please note that none of your plans affect your Safe to Spend because you’ve configured it to be based on your average spending in{' '}
          <Link to={Route.Settings}>Settings</Link>. As a result, your plans may not be on track.
        </Alert>
      )}

      <Nav variant="underline">
        {!isLoading && (
          <Nav.Item key={Tab.Recoveries}>
            <Nav.Link as={Link} eventKey={Tab.Recoveries} active={tab === Tab.Recoveries} to={Route.Recoveries}>
              {Tab.Recoveries}
            </Nav.Link>
          </Nav.Item>
        )}
        <Nav.Item key={Tab.Goals}>
          <Nav.Link as={Link} eventKey={Tab.Goals} active={tab === Tab.Goals} to={Route.Goals}>
            {Tab.Goals}
          </Nav.Link>
        </Nav.Item>
      </Nav>

      {error && <ErrorAlert error={error} />}

      {tab === Tab.Goals ? <Goals planId={planId} plans={plans} /> : <Recoveries planId={planId} plans={plans} />}
    </Stack>
  );
}

export default PlansPage;
export { Tab as PlansPageTab };
