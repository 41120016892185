import React from 'react';
import { Table } from 'react-bootstrap';
import { BsCashCoin, BsCreditCard, BsDash, BsEnvelopeOpenHeart } from 'react-icons/bs';
import DollarAmount from '../DollarAmount';
import useBudget from '../../hooks/useBudget';
import { Timescale } from '../../shared/BudgetCalendar';

export default function AllowanceTable() {
  const { budget } = useBudget();

  return (
    <Table size="sm" borderless style={{ marginBottom: 0 }}>
      <tbody>
        <tr>
          <td>
            <span className="text-muted" style={{ marginRight: 8 }}>
              <BsCashCoin />
            </span>
            <strong>Recurring income</strong>
          </td>
          <td style={{ textAlign: 'right' }} colSpan={2}>
            <DollarAmount amount={budget?.weekRecurrencePeriodIncomesSum} />
          </td>
        </tr>
        <tr>
          <td>
            <span className="text-muted" style={{ marginRight: 8, top: -1, position: 'relative' }}>
              <BsCreditCard />
            </span>
            <strong>Recurring expenses</strong>
          </td>
          <td>
            <BsDash />
          </td>
          <td style={{ textAlign: 'right' }}>
            <DollarAmount amount={budget?.weekRecurrencePeriodExpensesSum} keepSign />
          </td>
        </tr>
        <tr>
          <td>
            <span className="text-muted" style={{ marginRight: 8, top: -3, position: 'relative' }}>
              <BsEnvelopeOpenHeart />
            </span>
            <strong>Allowance</strong>
          </td>
          <td
            style={{
              textAlign: 'right',
              borderTop: '1px solid var(--bs-border-color)',
            }}
            colSpan={2}
          >
            <DollarAmount amount={budget?.monthAllowance} per={Timescale.Month} />
          </td>
        </tr>
        <tr>
          <td></td>
          <td
            style={{
              textAlign: 'right',
            }}
            colSpan={2}
          >
            <DollarAmount amount={budget?.weekAllowance} per={Timescale.Week} />
          </td>
        </tr>
      </tbody>
    </Table>
  );
}
