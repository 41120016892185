import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import {
  connectAuthEmulator,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithCustomToken,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { collection, connectFirestoreEmulator, doc, getDoc, getFirestore } from 'firebase/firestore';
import { UserDocData } from './types';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const functions = getFunctions(firebaseApp);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
let analytics = null;

const Collection = Object.freeze({
  users: 'users',
});

if (process.env.NODE_ENV !== 'production') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
  connectFirestoreEmulator(db, '127.0.0.1', 8080);
} else {
  analytics = getAnalytics(firebaseApp);
}

const _getLinkTokens = httpsCallable(functions, 'getLinkTokens');
const connectInstitution = httpsCallable(functions, 'connectInstitution');
const guessRecurringTransactions = httpsCallable(functions, 'guessRecurringTransactions');
const deleteUserPlaidItem = httpsCallable(functions, 'deleteUserPlaidItem');
const reconnectInstitution = httpsCallable(functions, 'reconnectInstitution');
const getImpersonationToken = httpsCallable(functions, 'getImpersonationToken');
const syncTransactions = httpsCallable(functions, 'syncTransactions');
const _recalculateBudgets = httpsCallable(functions, 'recalculateBudgets');

/**
 * Kicks off recalculation of budgets from a given date. If no date
 * is provided, then it will recalculate *all* budgets. See Budget.calculateAll()
 * for more details.
 *
 * Note: calling this function could take a very long time. As such, it is
 * not possible to await it.
 * @param {Date | undefined} date Optional date from whence to recalculate budgets.
 */
function recalculateBudgets(date?: Date) {
  return _recalculateBudgets({ date });
}

function getLinkTokens() {
  return _getLinkTokens();
}

const impersonate = async (token: string) => {
  try {
    return await signInWithCustomToken(auth, token);
  } catch (e) {
    console.error(e);
  }
};

const googleSignOut = async () => {
  await signOut(auth);
};

const googleSignIn = () => {
  return signInWithPopup(auth, new GoogleAuthProvider());
};

const appleSignIn = () => {
  return signInWithPopup(auth, new OAuthProvider('apple.com'));
};

const getDataForUser = async (uid: string) => {
  const collectionRef = collection(db, Collection.users);
  const userDoc = doc(collectionRef, uid);
  let dataSnapshot = await getDoc(userDoc);
  return dataSnapshot.data() as UserDocData;
};

export default db;
export {
  analytics,
  appleSignIn,
  auth,
  Collection,
  connectInstitution,
  deleteUserPlaidItem,
  getDataForUser,
  getImpersonationToken,
  getLinkTokens,
  googleSignIn,
  googleSignOut,
  guessRecurringTransactions,
  impersonate,
  recalculateBudgets,
  reconnectInstitution,
  syncTransactions,
};
