import React from 'react';
import { usePlaidLink } from 'react-plaid-link';
import BetterButton, { BetterButtonProps } from './BetterButton';

interface PlaidLinkButtonProps {
  linkToken: string;
  onSuccess: (publicToken: string, metadata: any) => void;
  variant?: 'outline-primary' | 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
  children: React.ReactNode;
  icon?: React.ReactNode;
  size?: 'sm' | 'lg';
  buttonProps?: BetterButtonProps;
}

export default function PlaidLinkButton({
  linkToken,
  onSuccess,
  variant = 'primary',
  children,
  icon,
  ...buttonProps
}: PlaidLinkButtonProps) {
  const { open, ready } = usePlaidLink({
    onSuccess,
    token: linkToken,
  });

  return (
    <BetterButton
      isLoading={!linkToken}
      variant={variant}
      beforeIcon={icon}
      disabled={!ready}
      onClick={() => open()}
      loadingText="Securing link"
      {...buttonProps}
    >
      {children}
    </BetterButton>
  );
}
