import React from 'react';
import { BsCashCoin, BsCreditCard, BsEnvelopeOpenHeart } from 'react-icons/bs';
import List from '../List';
import useBudget from '../../hooks/useBudget';
import DollarAmount from '../DollarAmount';

export default function AllowanceEquationWithIcons({ phase }: { phase?: number }) {
  const { budget } = useBudget();

  return (
    <List
      style={{
        justifyContent: 'center',
        flexDirection: 'row',
        color: phase !== undefined && phase !== 2 && 'var(--bs-secondary)',
        gap: 16,
      }}
    >
      <List.Item
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          color: phase >= 0 && 'var(--bs-body-color)',
        }}
        icon={<BsCashCoin size={24} />}
      >
        {phase >= 0 && budget?.weekRecurrencePeriodIncomesSum ? (
          <div style={{ textAlign: 'center' }}>
            <DollarAmount amount={budget?.weekRecurrencePeriodIncomesSum} />
            <div className="small-font">income</div>
          </div>
        ) : (
          'Income'
        )}
      </List.Item>
      <List.Item style={{ color: phase >= 1 && 'var(--bs-body-color)' }}>-</List.Item>
      <List.Item
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          color: phase === 1 && 'var(--bs-body-color)',
        }}
        icon={<BsCreditCard size={24} />}
      >
        {phase >= 1 && budget?.weekRecurrencePeriodExpensesSum ? (
          <div style={{ textAlign: 'center' }}>
            <DollarAmount amount={budget?.weekRecurrencePeriodExpensesSum} keepSign />
            <div className="small-font">expenses</div>
          </div>
        ) : (
          'Expenses'
        )}
      </List.Item>
      <List.Item>=</List.Item>
      <List.Item style={{ flexDirection: 'column', alignItems: 'center' }} icon={<BsEnvelopeOpenHeart size={24} />}>
        Allowance
      </List.Item>
    </List>
  );
}
