import React, { MouseEventHandler } from 'react';
import { BsApple } from 'react-icons/bs';
import BetterButton from './BetterButton';

export default function AppleButton({ label, onClick }: { label: string; onClick: MouseEventHandler }) {
  return (
    <BetterButton
      beforeIcon={<BsApple />}
      onClick={onClick}
      variant="dark"
      style={{
        alignItems: 'center',
        padding: '0.7em 2.2em',
        border: '1px solid var(--bs-light)',
        borderRadius: '2em',
        fontFamily: `-apple-system, BlinkMacSystemFont, 'avenir next', 'avenir', 'segoe ui', 'helvetica neue', 'helvetica', 'Cantarell', 'Ubuntu', 'roboto', 'noto', 'arial', sans-serif`,
      }}
    >
      {label}
    </BetterButton>
  );
}
