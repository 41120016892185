import roundCurrency from '../shared/roundCurrency';

type formatCurrencyOptions = {
  absolute?: boolean;
  decimals?: 'always' | 'never' | 'ifNotZero';
};

export default function formatCurrency(
  amount: number,
  options: formatCurrencyOptions = {
    absolute: false,
    decimals: 'ifNotZero',
  },
) {
  const roundedAmount = roundCurrency(amount);

  const formatOptions: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'USD',
    currencySign: 'accounting',
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  if ((options.decimals === 'ifNotZero' && Math.floor(roundedAmount) === roundedAmount) || options.decimals === 'never') {
    formatOptions.minimumFractionDigits = 0;
    formatOptions.maximumFractionDigits = 0;
  }

  if (options.absolute) {
    formatOptions.signDisplay = 'never';
  }

  return roundedAmount.toLocaleString('en-US', formatOptions);
}
