import React, { ForwardedRef } from 'react';
import { Badge, Dropdown, Image, Stack } from 'react-bootstrap';
import { forwardRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { PopupModal } from 'react-calendly';
import { useAuth } from '../App';
import {
  BsBank2,
  BsBookmarkStar,
  BsChatHeart,
  BsDiscord,
  BsGear,
  BsIncognito,
  BsMortarboard,
  BsPower,
  BsReddit,
  BsRocketTakeoff,
  BsShieldLock,
} from 'react-icons/bs';
import TextWithIcon from './TextWithIcon';
import { Route } from '../Routes';
import { useAppLayout } from '../layouts/AppLayout';
import dayjs from 'dayjs';

interface RefProps {
  onClick: () => void;
  email: string;
  photoURL: string;
  badgeCount: number;
}

const CustomToggle = forwardRef(({ onClick, email, photoURL, badgeCount }: RefProps, ref: ForwardedRef<any>) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      justifyContent: 'end',
      gap: 4,
      cursor: 'pointer',
    }}
  >
    <div
      style={{
        height: 28,
        width: 28,
        borderRadius: 28,
        background: 'var(--bs-primary)',
        color: 'white',
        textTransform: 'uppercase',
        textAlign: 'center',
        fontWeight: 'bold',
        lineHeight: '28px',
        position: 'relative',
      }}
      onClick={onClick}
      ref={ref}
    >
      {photoURL ? (
        <Image src={photoURL} height={28} width={28} style={{ display: 'block', borderRadius: '100%' }} />
      ) : (
        <>{email?.slice(0, 1)}</>
      )}

      {badgeCount > 0 && (
        <Badge bg="danger" style={{ position: 'absolute', top: -5, right: -5 }}>
          {badgeCount}
        </Badge>
      )}
    </div>
  </div>
));

export default function UserDropdownMenu() {
  const { userData, signOut, isOnboarding } = useAuth();
  const { plaidItemsForUpdate } = useAppLayout();
  const [showCalendlyPopup, setShowCalendlyPopup] = useState(false);

  if (!userData) return null;

  const { photoURL, displayName, email } = userData;

  const accountsNeedAttention = plaidItemsForUpdate.length;

  const headerParts = [];
  if (displayName) headerParts.push(displayName);
  headerParts.push(email);

  return (
    <>
      <Dropdown align="end">
        <Dropdown.Toggle email={email} photoURL={photoURL} as={CustomToggle} badgeCount={accountsNeedAttention} />

        <Dropdown.Menu>
          <Dropdown.Header>
            {headerParts.map((part, i) => (
              <div key={part}>{i === 0 ? <strong>{part}</strong> : <>{part}</>}</div>
            ))}
          </Dropdown.Header>
          <Dropdown.Divider />
          {!isOnboarding && (
            <>
              <Dropdown.Item as={Link} to={Route.Accounts}>
                <TextWithIcon icon={<BsBank2 color="var(--bs-secondary)" />} reverse>
                  <Stack direction="horizontal" gap={2}>
                    <div>Accounts</div>
                    {accountsNeedAttention > 0 && <Badge bg="danger">{accountsNeedAttention}</Badge>}
                  </Stack>
                </TextWithIcon>
              </Dropdown.Item>
              <Dropdown.Item as={Link} to={Route.Settings}>
                <TextWithIcon icon={<BsGear color="var(--bs-secondary)" />} reverse>
                  <div>Settings</div>
                </TextWithIcon>
              </Dropdown.Item>
              <Dropdown.Divider />
            </>
          )}
          <Dropdown.Header>Help & Feedback</Dropdown.Header>
          <Dropdown.Item as={Link} to={Route.Help}>
            <TextWithIcon icon={<BsMortarboard color="var(--bs-secondary)" />} reverse>
              Help Center
            </TextWithIcon>
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setShowCalendlyPopup(true)}>
            <TextWithIcon icon={<BsChatHeart color="var(--bs-secondary)" />} reverse>
              Talk to a founder
            </TextWithIcon>
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="https://discord.gg/Fqz7j5HyPs" target="_blank">
            <TextWithIcon icon={<BsDiscord color="var(--bs-secondary)" />} reverse>
              Discord
            </TextWithIcon>
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="https://www.reddit.com/r/lopery" target="_blank">
            <TextWithIcon icon={<BsReddit color="var(--bs-secondary)" />} reverse>
              Reddit
            </TextWithIcon>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Header>About Lopery</Dropdown.Header>
          <Dropdown.Item as="a" href={Route.Blog}>
            <TextWithIcon icon={<BsBookmarkStar color="var(--bs-secondary)" />} reverse>
              Blog
            </TextWithIcon>
          </Dropdown.Item>
          <Dropdown.Item as={Link} to={Route.AboutUs}>
            <TextWithIcon icon={<BsRocketTakeoff color="var(--bs-secondary)" />} reverse>
              About us
            </TextWithIcon>
          </Dropdown.Item>
          <Dropdown.Item as={Link} to={Route.Security}>
            <TextWithIcon icon={<BsShieldLock color="var(--bs-secondary)" />} reverse>
              Security
            </TextWithIcon>
          </Dropdown.Item>
          <Dropdown.Item as={Link} to={Route.Privacy}>
            <TextWithIcon icon={<BsIncognito color="var(--bs-secondary)" />} reverse>
              Privacy
            </TextWithIcon>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={signOut}>
            <TextWithIcon icon={<BsPower color="var(--bs-secondary)" />} reverse>
              Sign out
            </TextWithIcon>
          </Dropdown.Item>
          <Dropdown.Header>©️ Lopery Inc., {dayjs().format('YYYY')}</Dropdown.Header>
        </Dropdown.Menu>
      </Dropdown>
      <PopupModal
        open={showCalendlyPopup}
        url="https://calendly.com/avand/lopery-chat"
        rootElement={document.getElementById('root')}
        onModalClose={() => setShowCalendlyPopup(false)}
      />
    </>
  );
}
