import React from 'react';
import { Spinner } from 'react-bootstrap';

interface LoadingNumberProps {
  isLoading: boolean;
  num: number;
}

export default function LoadingNumber({ isLoading, num }: LoadingNumberProps) {
  if (isLoading) return <Spinner size="sm" />;

  return <span>{num.toLocaleString()}</span>;
}
