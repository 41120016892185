import { collection, deleteDoc, deleteField, doc, getDocs, query, runTransaction, Timestamp, where } from 'firebase/firestore';
import db, { recalculateBudgets } from '../firebase';
import { PlanDocData } from '../types';

async function removePlan(uid: string, plan: PlanDocData) {
  const { __id: planId, transactionId, start } = plan;

  // Delete installments first, then the plan. This way, the snapshot listening for plan changes
  // won't trigger immediately and wipe the UI before the installments are deleted.
  const installments = await getDocs(query(collection(db, 'users', uid, 'installments'), where('planId', '==', planId)));
  for (const installment of installments.docs) await deleteDoc(installment.ref);

  await runTransaction(db, async (transaction) => {
    if (transactionId) {
      const planTransaction = await transaction.get(doc(db, 'users', uid, 'transactions', transactionId));

      if (planTransaction.exists()) {
        transaction.set(
          doc(db, 'users', uid, 'transactions', transactionId),
          {
            planId: deleteField(),
            updatedAt: Timestamp.now(),
          },
          { merge: true },
        );
      }
    }

    transaction.delete(doc(db, 'users', uid, 'plans', planId));
  });

  await recalculateBudgets(start.toDate());
}

async function createPlan(uid: string, planData: PlanDocData) {
  const { transactionId, start } = planData;
  await runTransaction(db, async (transaction) => {
    const planDocRef = doc(collection(db, 'users', uid, 'plans'));

    if (transactionId) {
      const planTransaction = await transaction.get(doc(db, 'users', uid, 'transactions', transactionId));

      if (!planTransaction.exists()) {
        throw new Error('Transaction not found, could not create the recovery.');
      }

      transaction.set(
        doc(db, 'users', uid, 'transactions', transactionId),
        {
          isRecurring: false,
          isIgnored: false,
          planId: planDocRef.id,
          updatedAt: Timestamp.now(),
        },
        { merge: true },
      );
    }

    transaction.set(planDocRef, planData);
  });

  recalculateBudgets(start.toDate()).catch();
}

export { removePlan, createPlan };
