import { Dayjs } from 'dayjs';
import { utcDay } from './days';

const DAYS_IN_WEEK = 7;
const WEEKS_IN_MONTH = 52 / 12;

enum Timescale {
  Week = 'week',
  Month = 'month',
}

class Period {
  start: Dayjs;
  end: Dayjs;

  constructor(start: Dayjs, end: Dayjs) {
    if (start.isAfter(end)) throw new Error('Start date must be before end date');

    this.start = start.startOf('day');
    this.end = end.endOf('day');
  }

  endOfPrevious() {
    return this.start.subtract(1, 'millisecond');
  }
}

interface BudgetPeriods {
  recurrence: Period;
  utilization: Period;
}

class BudgetCalendar {
  now: Dayjs;

  constructor() {
    this.now = utcDay();
  }

  static getBudgetPeriodsByDate(timescale = Timescale.Week, date: Dayjs) {
    const utilizationStart = date.startOf(timescale);
    const utilizationEnd = date.endOf(timescale);

    return {
      recurrence: this.getRecurrencePeriod(utilizationStart),
      utilization: new Period(utilizationStart, utilizationEnd),
    } as BudgetPeriods;
  }

  static getRecurrencePeriod(utilizationPeriodStart: Dayjs) {
    return new Period(utilizationPeriodStart.subtract(1, 'month'), utilizationPeriodStart.subtract(1, 'millisecond'));
  }

  /**
   * Returns two sets of date ranges that can be used to load transactions
   * and budgets. The first set, `week`, represents the start and
   * end of the period used to calculate usage against an allowance.
   * The second set, `recurrence`, represents the start and end of the period
   * used to calculate allowance (recurring income - recurring expenses).
   * By providing a `timescale` of 'month', these ranges will represent
   * a full month. By providing an `offset`, you can traverse to the prior
   * period.
   * @param timescale Defaults to 'week' but can also be set to 'month'
   * @param offset Defaults to 0, the current period; the higher the number, the further back in time
   */
  getBudgetPeriodsByOffset(timescale = Timescale.Week, offset = 0): BudgetPeriods {
    const nowOffset = this.now.subtract(offset, timescale);

    // If you change the rules here, also change functions/src/models/Budget.js
    const utilizationEnd = nowOffset.endOf(timescale);
    const utilizationStart = nowOffset.startOf(timescale);

    return {
      recurrence: BudgetCalendar.getRecurrencePeriod(utilizationStart),
      utilization: new Period(utilizationStart, utilizationEnd),
    };
  }

  /**
   * Perhaps a little clunkily named, this method tells you if the
   * provided date is for the "current" period (i.e. the period that
   * covers today/now).
   */
  static isNowPeriod(date: Dayjs, timescale: Timescale) {
    const {
      utilization: { start },
    } = new BudgetCalendar().getBudgetPeriodsByOffset(timescale);

    return start.isSame(date.startOf(timescale));
  }
}

export default BudgetCalendar;
export { Timescale, DAYS_IN_WEEK, WEEKS_IN_MONTH, BudgetPeriods, Period };
