import React, { useCallback, useState } from 'react';
import { removePlan } from '../../utils/plan';
import { useAuth } from '../../App';
import { Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Route } from '../../Routes';
import { PlanDocData } from '../../types';
import { BsCashCoin, BsQuestionCircle } from 'react-icons/bs';
import DollarAmount from '../DollarAmount';
import { utcDay } from '../../shared/days';
import BetterButton from '../BetterButton';

interface DeletePlanButtonProps {
  plan: PlanDocData;
}

export default function DeletePlanButton({ plan }: DeletePlanButtonProps) {
  const [isConfirming, setIsConfirming] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { uid } = useAuth();
  const navigate = useNavigate();

  const handleDelete = useCallback(async () => {
    try {
      setIsDeleting(true);
      await removePlan(uid, plan);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDeleting(false);
      navigate(plan.transactionId ? Route.Recoveries : Route.Goals);
    }
  }, [uid, plan, setIsDeleting, navigate]);

  const handleConfirm = useCallback(async () => {
    if (isConfirming) await handleDelete();
    else setIsConfirming(true);
  }, [setIsConfirming, handleDelete, isConfirming]);

  return (
    <div>
      <Stack gap={3}>
        {plan.transactionId ? (
          <div className="small-font text-muted">
            Deleting this recovery will reduce this week’s Safe to Spend by{' '}
            <DollarAmount keepSign amount={plan.targetAmount - plan.installmentsSum} />.
          </div>
        ) : (
          <div className="small-font text-muted">
            Cash-in the <DollarAmount amount={plan.installmentsSum} keepSign /> you’ve saved since{' '}
            {utcDay(plan.start.toDate()).format('MMMM DD, YYYY')}. This amount will be applied to your current Safe to Spend. This action
            cannot be undone.
          </div>
        )}
        <Stack direction="horizontal" gap={2}>
          {plan.transactionId ? (
            <BetterButton variant="danger" isLoading={isDeleting} onClick={isConfirming ? handleDelete : handleConfirm}>
              {isConfirming ? 'Are you sure?' : 'Delete'}
            </BetterButton>
          ) : (
            <BetterButton
              variant={isConfirming ? 'danger' : 'success'}
              beforeIcon={isConfirming ? <BsQuestionCircle /> : <BsCashCoin />}
              isLoading={isDeleting}
              onClick={isConfirming ? handleDelete : handleConfirm}
            >
              {isConfirming ? 'Are you sure?' : 'Close & cash-in'}
            </BetterButton>
          )}
          {isConfirming && (
            <BetterButton variant="link" onClick={() => setIsConfirming(false)} disabled={isDeleting}>
              Cancel
            </BetterButton>
          )}
        </Stack>
      </Stack>
    </div>
  );
}
