import { createContext, useContext } from 'react';
import { BudgetPeriods, Timescale } from '../shared/BudgetCalendar';
import BudgetView from '../shared/BudgetView';
import { AccountDocData, BudgetDocData } from '../types';

interface DashboardContextType {
  accountsById?: any;
  budget?: BudgetDocData;
  budgetPeriods?: BudgetPeriods;
  budgetView?: BudgetView;
  includedAccounts?: AccountDocData[];
  isCreatingBudget?: boolean;
  runway?: any;
  setShowBudgetModal?: (value: boolean) => void;
  showBudgetModal?: boolean;
  timescale?: Timescale;
  transactions?: any;
}

const DashboardContext = createContext<DashboardContextType>({});

function useDashboard() {
  return useContext(DashboardContext);
}

export default DashboardContext;
export { useDashboard };
