import React from 'react';
import usePlans from '../../hooks/usePlans';
import { Col, Row, Stack } from 'react-bootstrap';
import { filter } from 'underscore';
import { NewGoalButton } from './NewGoalButton';
import Plan from './Plan';
import PlanModal from './PlanModal';
import { PlanDocData } from '../../types';

interface PlansProps {
  plans: PlanDocData[];
  planId?: string;
}

export default function Goals({ plans, planId }: PlansProps) {
  const plan = plans.find((plan) => plan.__id === planId);

  let filteredPlans: PlanDocData[];
  // first the perpetual goals sorted by amount per week
  filteredPlans = filter(plans, (plan) => plan.isPerpetual).sort((a, b) => b.installmentsSum - a.installmentsSum);

  // then the active goals sorted by amount complete
  filteredPlans = filteredPlans.concat(
    filter(plans, (plan) => !plan.isPerpetual && plan.installmentsSum !== plan.targetAmount).sort(
      (a, b) => b.installmentsSum / b.targetAmount - a.installmentsSum / a.targetAmount,
    ),
  );
  // then the completed goals sorted by amount saved
  filteredPlans = filteredPlans.concat(
    filter(plans, (plan) => plan.installmentsSum === plan.targetAmount).sort((a, b) => b.installmentsSum - a.installmentsSum),
  );

  return (
    <Stack gap={3}>
      <Row>
        <Col xs={6}>
          <h3>Goals</h3>
        </Col>
        <Col xs={6}>
          <div className="d-flex justify-content-end">
            <NewGoalButton />
          </div>
        </Col>
      </Row>

      {plans.length === 0 && <p className="mb-0 text-muted">No goals.</p>}

      <div>
        {filteredPlans.map((plan: PlanDocData, i) => (
          <Plan plan={plan} key={plan.__id} />
        ))}
      </div>

      {plan && <PlanModal plan={plan} show={true} />}
    </Stack>
  );
}

export { usePlans };
