import React from 'react';
import { AccountDocData } from '../types';
import NoWrap from './NoWrap';

interface AccountMaskProps {
  account: AccountDocData;
}

export default function AccountMask({ account }: AccountMaskProps) {
  return (
    <NoWrap data-account-id={account.accountId}>
      {account.institution.name}
      {account.mask && <> x{account.mask}</>}
    </NoWrap>
  );
}
