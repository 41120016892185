import React from 'react';
import Accounts from '../Accounts';

export default function ConnectAccountsStep() {
  return (
    <>
      <section>
        <strong>Connect your income & expense accounts.</strong> For most people, this is a combination of a checking account and a credit
        card. Please, no savings, investment or retirement accounts.
      </section>

      <section>
        <Accounts />
      </section>
    </>
  );
}
