import React, { ReactNode } from 'react';

export default function DotSeparator() {
  return <span style={{ margin: '0 6px' }}>ᐧ</span>;
}

interface ComponentsSeparatedByDotsProps {
  components: ReactNode[];
}

export function ComponentsSeparatedByDots({ components }: ComponentsSeparatedByDotsProps) {
  // Filter out falsey values for easier usage (no way to filter out components that return null)
  const componentsToRender = components.filter((child) => !!child);

  const withSeparators = componentsToRender.flatMap((child, index) =>
    index < componentsToRender.length - 1
      ? [<span key={`child-${index}`}>{child}</span>, <DotSeparator key={`dot-${index}`} />]
      : [<span key={`child-${index}`}>{child}</span>],
  );

  return <>{withSeparators}</>;
}
